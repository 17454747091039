import React, { createContext, useContext, useEffect } from 'react'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from './firebaseConfig'

import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionGroup,
  deleteDoc as delDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from 'firebase/firestore'
import {
  getMetadata,
  listAll,
  uploadBytes,
  uploadString,
} from 'firebase/storage'

import {
  ApplicationVerifier,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  updatePassword,
} from 'firebase/auth'
import {
  deleteObject,
  getBlob,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useDispatch, useSelector } from 'react-redux'

import {
  login,
  logout,
  logoutError,
  setCustomer,
  setInitalizeApp,
} from '../store/auth'
import { RootState } from '../store'
import { getImageSizes } from '../helpers'
import async, {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../store/async'
import { notify } from '../helpers/index'
import { onStateChanged, onError } from './upload-images'
import Value from './Value'
import JSZip from 'jszip'

const FirebaseContext = createContext<Value>({})
export { FirebaseContext }

export function useFirebase() {
  return useContext(FirebaseContext)
}

const FirebaseProvider = ({ children }: { children: any }) => {
  const app = initializeApp(firebaseConfig)
  const store = getFirestore(app)
  const auth = getAuth(app)
  const auth2 = getAuth()
  const storage = getStorage(app)
  const storage_lidar = getStorage(app, 'gs://co-one-app-1252e-import-files')
  const functions = getFunctions(app)
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      // user = JSON.parse(JSON.stringify(user));
      if (user) {
        dispatch(login(user))
      } else {
        dispatch(login(null))
      }
    })
  }, [dispatch, auth])

  useEffect(() => {
    if (user) {
      const unsub = onSnapshot(doc(store, `Customers/${user?.uid}`), (doc) => {
        // console.log(user)
        // console.log(doc.data)
        // add id to doc.data
        dispatch(setCustomer({ id: doc.id, ...doc.data() }))
        dispatch(setInitalizeApp())
      })

      return () => {
        unsub()
      }
    }
  }, [user, dispatch, store])

  async function getDocByPath(path: string) {
    const docRef = doc(store, path)
    const docSnap = await getDoc(docRef)
    return { id: docSnap.id, ...docSnap.data() }
  }

  async function getAllDocsByPath(path: string) {
    const docsRef = collection(store, path)
    const docsSnap = await getDocs(docsRef)
    return docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  async function getAllDocsByPathAndQuery(
    path: string,
    queryName: string,
    sort: any
  ) {
    const docsRef = collection(store, path)
    const docsSnap = await getDocs(
      query(docsRef, orderBy(queryName, sort), limit(100))
    )
    return docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  async function signin(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  async function createNewAccount(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  async function reauthenticate(password: string) {
    try {
      const currentUser: any = auth.currentUser
      const credential = EmailAuthProvider.credential(
        currentUser?.email,
        password
      )
      const result = await reauthenticateWithCredential(currentUser, credential)
      console.log(result)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function updateUserPassword(password: string) {
    try {
      const currentUser: any = auth.currentUser
      await updatePassword(currentUser, password)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function signout() {
    try {
      await signOut(auth)
      dispatch(logout())
    } catch (error) {
      console.log(error)
      dispatch(logoutError(error))
    }
  }

  async function getNotifications() {
    const notificationsRef = collection(store, 'CDNotifications')
    const notifsQuery = query(
      notificationsRef,
      where(`receivers.${user.uid}`, '!=', null)
    )
    const notifsSnap = await getDocs(notifsQuery)
    const notifs = notifsSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    return notifs
  }

  function listenToNotifications() {
    const notificationsRef = collection(store, 'CDNotifications')
    const notifsQuery = query(
      notificationsRef,
      where(`receivers.${user.uid}`, '!=', null)
    )
    return notifsQuery
  }

  async function getTrackingUsers(projectID: string) {
    const addedCustomersRef = collection(
      store,
      'Projects',
      projectID,
      'addedCustomers'
    )
    const addedCustomersSnap = await getDocs(addedCustomersRef)
    const addedCustomers = addedCustomersSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    return addedCustomers
  }

  async function addCustomer(email: string, role: string, projectID: string) {
    const customersRef = collection(store, 'Customers')
    const customerQuery = query(customersRef, where('email', '==', email))
    const customerSnapshot = await getDocs(customerQuery)
    if (!customerSnapshot.empty) {
      try {
        const trackingUserRef = doc(
          store,
          'Projects',
          projectID,
          'addedCustomers',
          user.userID
        )
        await setDoc(trackingUserRef, { roleRef: role })
        const trackingProjectRef = doc(
          store,
          'Customers',
          user.userID,
          'trackingProjects',
          projectID
        )
        await setDoc(trackingProjectRef, {})
        return true
      } catch (error) {
        return false
      }
    }
  }

  async function removeCustomer(user: any, projectID: string) {
    try {
      const trackingProjectRef = doc(
        store,
        'Customers',
        user.userID,
        'trackingProjects',
        projectID
      )
      const trackingUserRef = doc(
        store,
        'Projects',
        projectID,
        'addedCustomers',
        user.userID
      )
      await delDoc(trackingProjectRef)
      await delDoc(trackingUserRef)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function getUser(userID: string) {
    const userRef = doc(store, 'Customers', userID)
    const userSnap = await getDoc(userRef)
    return { id: userSnap.id, ...userSnap.data() }
  }

  async function setAutoAddDomain(projectID: string, payload: any) {
    const projectRef = doc(store, 'Projects', projectID)
    setDoc(
      projectRef,
      {
        autoAddDomain: payload,
      },
      { merge: true }
    )
  }

  async function getBlobUrlByPath(path: string) {
    const pathRef = ref(storage, path)
    const blobFile = await getBlob(pathRef)
    const url = URL.createObjectURL(blobFile)
    return url
  }

  async function getAllProjects() {
    if (user) {
      const trackingProjectsRef = collection(
        store,
        'Customers',
        user.uid,
        'trackingProjects'
      )
      const trackingProjectsSnap = await getDocs(trackingProjectsRef)
      const projectIds = trackingProjectsSnap.docs.map((doc) => doc.id)
      const projects: any[] = []

      for (let i = 0; i < projectIds.length; i += 10) {
        const slicedIds = projectIds.slice(i, i + 10)
        const projectsRef = collection(store, 'Projects')
        const projectsQuery = query(
          projectsRef,
          where('__name__', 'in', slicedIds)
        )
        const projectsSnap = await getDocs(projectsQuery)
        const slicedProjects = projectsSnap.docs.map((doc) => ({
          projectID: doc.id,
          ...doc.data(),
        }))
        projects.push(...slicedProjects)
      }
      return projects
    } else {
      throw new Error('You need to login first!')
    }
  }

  function listenToProjects() {
    if (user) {
      const projectsCollection = collection(
        store,
        'Customers',
        user.uid,
        'trackingProjects'
      )
      return projectsCollection
    } else {
      throw new Error('You need to login first!')
    }
  }

  function listenToSubscriptions() {
    if (user) {
      const projectsCollection = collection(
        store,
        'Customers',
        user.uid,
        'subscriptions'
      )
      return projectsCollection
    } else {
      throw new Error('You need to login first!')
    }
  }

  function listenToTrackingUsers(projectID: string) {
    if (!projectID) return
    if (user) {
      const addedCustomers = collection(
        store,
        'Projects',
        projectID,
        'addedCustomers'
      )
      return addedCustomers
    } else {
      throw new Error('You need to login first!')
    }
  }

  async function getAddedCustomers(projectID: string) {
    if (!projectID) return
    if (user) {
      const customersRef = collection(
        store,
        'Projects',
        projectID,
        'addedCustomers'
      )
      const customersSnap = await getDocs(customersRef)
      return customersSnap
    } else {
      throw new Error('You need to login first!')
    }
  }

  async function getAllTaskUser(projectID: string) {
    if (!projectID) return
    if (user) {
      const tasksRef = collection(store, 'Projects', projectID, 'tasks')
      const taskSnap = await getDocs(tasksRef)
      return taskSnap
    } else {
      throw new Error('You need to login first!')
    }
  }

  async function getExport(projectID: string) {
    const collectionPath = 'Projects/168792_multiClassification/tasks'
    const collectionRef = collection(store, collectionPath)
    // const attrsQuery = query(
    //   collectionGroup(store, 'objects'),
    //   where('projectID', '==', '7052_polygon')
    // )
    // const querySnapshot = await getDocs(attrsQuery)

    // const objectCounts: any = {}
    // const objectCountsD: any = {}

    // querySnapshot.forEach((doc) => {
    //   const data = doc.data()
    //   const objectName = data.objectName
    //   const objectCount = data.objectCount || 0

    //   const gee =
    //     data.customerAnnotation?.length > 0
    //       ? data.customerAnnotation?.length
    //       : data.groundTruth?.length > 0
    //       ? data.groundTruth?.length
    //       : 0

    //   if (objectName) {
    //     if (objectCounts[objectName]) {
    //       objectCounts[objectName] += objectCount
    //       objectCountsD[objectName] += gee
    //     } else {
    //       objectCounts[objectName] = objectCount
    //       objectCountsD[objectName] = gee
    //     }
    //   }
    // })
    // console.log(objectCounts)
    // console.log(objectCountsD)
    // const dat = {}
    // const collectionPath = 'Projects/951580_boundingBox/dataset'
    // const collectionRef = collection(store, collectionPath)
    // console.log(Object.keys(dat).length)
    // for (const image of Object.keys(dat)) {
    //   for (const object of Object.keys(dat[image])) {
    //     const docRef = doc(
    //       collection(
    //         store,
    //         'Projects',
    //         '35970_boundingBox',
    //         'dataset',
    //         image,
    //         'objects'
    //       ),
    //       object
    //     )
    //     updateDoc(docRef, {
    //       [`crossValidated.boundingBox`]: true,
    //       [`crossValidated.multiClassification`]: true,
    //       groundTruth: dat[image][object],
    //     })
    //   }
    //   const doc2Ref = doc(
    //     collection(store, 'Projects', '35970_boundingBox', 'dataset'),
    //     image
    //   )
    //   updateDoc(doc2Ref, {
    //     [`crossValidated.boundingBox`]: true,
    //     [`crossValidated.multiClassification`]: true,
    //   })
    //   console.log(image)
    // }

    // const projectDatasetRef = query(
    //   collection(store, '/Projects/951580_boundingBox/dataset/'),
    //   where('crossValidated.boundingBox', '==', true)
    // )
    // const data: any = {}
    // const projectDatasetSnap = await getDocs(projectDatasetRef)
    // projectDatasetSnap.docs.forEach(async (docID, i) => {
    //   const docRef = doc(
    //     store,
    //     `/Projects/951580_boundingBox/dataset/${docID.id}/objects/truck`
    //   )
    //   const docSnap = await getDoc(docRef)
    //   if (docSnap.exists() && docSnap.data().customerAnnotation) {
    //     console.log(docID.id, docSnap.data().customerAnnotation)
    //   }
    //   if (docSnap.exists() && docSnap.data().groundTruth) {
    //     data[docID.id] = {
    //       ...data[docID.id],
    //       truck: docSnap.data().customerAnnotation
    //         ? docSnap.data().customerAnnotation
    //         : docSnap.data().groundTruth,
    //     }
    //   }

    //   const docRef2 = doc(
    //     store,
    //     `/Projects/951580_boundingBox/dataset/${docID.id}/objects/car`
    //   )
    //   const docSnap2 = await getDoc(docRef2)
    //   if (docSnap2.exists() && docSnap2.data().groundTruth) {
    //     data[docID.id] = {
    //       ...data[docID.id],
    //       car: docSnap2.data().customerAnnotation
    //         ? docSnap2.data().customerAnnotation
    //         : docSnap2.data().groundTruth,
    //     }
    //   }

    //   const docRef3 = doc(
    //     store,
    //     `/Projects/951580_boundingBox/dataset/${docID.id}/objects/bus`
    //   )
    //   const docSnap3 = await getDoc(docRef3)
    //   if (docSnap3.exists() && docSnap3.data().groundTruth) {
    //     data[docID.id] = {
    //       ...data[docID.id],
    //       bus: docSnap3.data().customerAnnotation
    //         ? docSnap3.data().customerAnnotation
    //         : docSnap3.data().groundTruth,
    //     }
    //   }

    //   const docRef4 = doc(
    //     store,
    //     `/Projects/951580_boundingBox/dataset/${docID.id}/objects/no_label`
    //   )
    //   const docSnap4 = await getDoc(docRef4)
    //   if (docSnap4.exists() && docSnap4.data().groundTruth) {
    //     data[docID.id] = {
    //       ...data[docID.id],
    //       no_label: docSnap4.data().customerAnnotation
    //         ? docSnap4.data().customerAnnotation
    //         : docSnap4.data().groundTruth,
    //     }
    //   }

    //   //last index
    //   if (i === projectDatasetSnap.docs.length - 1) {
    //     console.log('last index')
    //     console.log(data)
    //   }
    // })

    // const getNumbers = (word: any) => {
    //   const match = word.match(/\d+$/)
    //   return match ? parseInt(match[0], 10) : null
    // }

    // // sort by imageID and createdDate update by imageID by sort
    // const queryRef = query(collectionRef, orderBy('imageID', 'asc'))
    // const querySnap = await getDocs(queryRef)
    // const queryData = querySnap.docs.map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }))
    // for (const doc2 of queryData) {
    //   console.log(doc2.id)
    //   const docRef = doc(store, `${collectionPath}/${doc2.id}`)
    //   const indx = getNumbers(doc2.id)
    //   await updateDoc(docRef, {
    //     createdDate: indx,
    //   })
    // }
    // console.log(queryData)

    // const images = ['AlAin_r30_stitched_img_crop26']

    // const queryRef = query(collectionRef, orderBy('createdDate', 'asc'))
    // const querySnapshot = await getDocs(queryRef)
    // for (let i = 0; i < querySnapshot.docs.length; i++) {
    //   const doc = querySnapshot.docs[i]
    //   let imageIndex = i
    //   if (imageIndex === 1) {
    //     console.log(doc.id, imageIndex)
    //     console.log('dasdasd')
    //   }
    //   if (imageIndex > 500 && imageIndex < 600) {
    //     console.log(doc.id, imageIndex, doc.data())
    //     console.log(doc.data().crossValidated.boundingBox)
    //   }
    // }

    // const timestamp = Date.now()
    // const dailyTimeStamp = new Date().setHours(0, 0, 0, 0)
    let cntr = 0
    let classes = [
      'beverage',
      'cleaning_materials',
      'electronics',
      'food',
      'personal_care',
    ]

    // const collectionPath = 'Projects/795691_boundingBox/dataset'
    // const collectionRef = collection(store, collectionPath)
    // // query only webReview=false
    // const queryRef = query(collectionRef, where('isBusy', '==', false))
    // const querySnap = await getDocs(queryRef)
    // const slicedProjects = querySnap.docs.length
    // const firstDoc = querySnap.docs[0]
    // console.log(slicedProjects)
    // querySnap.forEach(async (doc) => {
    //   try {
    //     // console.log(doc.data().sizes, doc.id)
    //     // const docRef = doc.ref
    //     // await updateDoc(docRef, { sizes: { width: 1200, height: 1800 } })
    //     // console.log(`size yazdım ${doc.id}`)
    //     // return
    //   } catch (error) {
    //     console.error(`Belge güncellenirken bir hata oluştu: ${error}`)
    //   }
    // })

    // async function addAnnotations(doc2: any, item: any) {
    //   console.time('blockTime' + doc2.id + item)

    //   const docRef2 = collection(
    //     store,
    //     `Projects/601455_boundingBox/dataset/${
    //       doc2.data().imageID
    //     }/objects/${item}/annotations`
    //   )

    //   const result = await addDoc(docRef2, {
    //     annotation: [],
    //     annotationType: 'boundingBox',
    //     boosts: {
    //       cpBoost12: false,
    //       cpBoost15: false,
    //       payrateBoost12: false,
    //       payrateBoost15: false,
    //     },
    //     customerID: 'R3o375YIFPMwLAObgcocwIzhFIz2',
    //     dailyTimeStamp: dailyTimeStamp,
    //     fromReviewer: false,
    //     fromWebTool: true,
    //     imageID: doc2.data().imageID,
    //     imageWidthHeight: doc2.data().sizes,
    //     isGolden: false,
    //     objectCount: 0,
    //     objectID: item,
    //     objectName: item,
    //     projectID: '601455_boundingBox',
    //     timestamp: timestamp,
    //     userID: 'modelValidation',
    //   })

    //   console.log('done', doc2.data().imageID, result.id)
    //   console.timeEnd('blockTime' + doc2.id + item)
    // }
    // const promises: any = []
    // querySnapshot.forEach(async (doc2) => {
    //   console.time('blockTime' + doc2.id)
    //   const docRef = doc(store, collectionPath, doc2.id)
    //   promises.push(
    //     getDoc(docRef).then(async (docSnap) => {
    //       const docData = docSnap.data()
    //       const classPromises = classes.map((item) =>
    //         addAnnotations(doc2, item)
    //       )

    //       // Wait for all annotations for the current image to finish
    //       await Promise.all(classPromises)

    //       console.timeEnd('blockTime' + doc2.id)
    //       console.log(docData)
    //     })
    //   )
    //   await Promise.all(promises)
    //   // if (
    //   //   !doc2.data().crossValidated.boundingBox &&
    //   //   !doc2.data().webReview &&
    //   //   cntr < 2
    //   // ) {
    //   //   console.log(doc2.data())
    //   //   const docRef = collection(
    //   //     store,
    //   //     `Projects/601455_boundingBox/dataset/${doc2.data().imageID}/objects`
    //   //   )
    //   //   const docSnap = await getDocs(docRef)
    //   //   docSnap.forEach(async document => {
    //   //     const docRef2 = collection(
    //   //       store,
    //   //       `Projects/601455_boundingBox/dataset/${
    //   //         doc2.data().imageID
    //   //       }/objects/${document.id}/annotations`
    //   //     )
    //   //     await addDoc(docRef2, {
    //   //       annotation: [],
    //   //       annotationType: 'boundingBox',
    //   //       boosts: {
    //   //         cpBoost12: false,
    //   //         cpBoost15: false,
    //   //         payrateBoost12: false,
    //   //         payrateBoost15: false,
    //   //       },
    //   //       customerID: 'R3o375YIFPMwLAObgcocwIzhFIz2',
    //   //       dailyTimeStamp: dailyTimeStamp,
    //   //       fromReviewer: false,
    //   //       fromWebTool: true,
    //   //       imageID: doc2.data().imageID,
    //   //       imageWidthHeight: doc2.data().sizes,
    //   //       isGolden: false,
    //   //       objectCount: 0,
    //   //       objectID: document.id,
    //   //       objectName: document.id,
    //   //       projectID: '601455_boundingBox',
    //   //       timestamp: timestamp,
    //   //       userID: 'modelValidation',
    //   //     }).then(res => {
    //   //       console.log('done', doc2.data().imageID, res.id)
    //   //     })
    //   //   })
    //   //   cntr++
    //   // }
    // })

    // for (const imageObject of imagesObject) {
    //   let data: any = {
    //     file: imageObject.id.split('.')[0] + '.json',
    //     frame_id: imageObject.id,
    //     openlabel: {
    //       openlabel: {
    //         actions: {},
    //         coordinate_systems: {},
    //         events: {},
    //         frame_intervals: [
    //           {
    //             frame_end: 0,
    //             frame_start: 0,
    //           },
    //         ],
    //         metadata: {
    //           schema_version: '2.1.0',
    //           format_variant: 'label-bolf',
    //           uuid: 'f4248164-086e-40c1-bdf9-1b8c58788d78',
    //           parent_uuid: '39f59c2b-bc7e-4ba9-88bb-e72fe3ba7c3d',
    //           annotator: 'SupplierX',
    //           comment: 'Label Guide Version: x.x.x',
    //         },
    //         ontologies: {},
    //       },
    //     },
    //   }

    //   const { tags } = imageObject
    //   const contexts: any = {}

    //   const frames: any = {
    //     '0': {
    //       contexts: {},
    //       frame_properties: {
    //         streams: {
    //           BOSCH_FCH_FC1_JP: {
    //             uri: imageObject.id,
    //           },
    //         },
    //         timestamp: 0,
    //         external_id: '189587',
    //       },
    //       objects: {},
    //     },
    //   }

    //   tags.forEach((item: any, index: any) => {
    //     const context = {
    //       name: '',
    //       type: item.category,
    //     }
    //     contexts[index] = context

    //     const contextData: any = {
    //       [index]: {
    //         context_data: {
    //           text: [],
    //         },
    //       },
    //     }

    //     contextData[index].context_data.text.push({
    //       name: 'value',
    //       val: item.value.split('__')[1],
    //     })

    //     Object.assign(frames['0'].contexts, contextData)
    //   })

    //   data.openlabel.openlabel = {
    //     ...data.openlabel.openlabel,
    //     contexts,
    //     frames,
    //   }

    //   const docsQuery = query(
    //     collectionGroup(store, 'objects'),
    //     where('projectID', '==', '598852_polyline-bs'),
    //     where('imageID', '==', imageObject.id)
    //   )
    //   const docsSnap = await getDocs(docsQuery)
    //   const annotations = docsSnap.docs.map(doc => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }))

    //   const objects: any = {}
    //   const objects2: any = []

    //   for (const item of annotations) {
    //     const anno: any = item
    //     if (!anno.groundTruth) continue
    //     const objectID = anno.id
    //     const objectName = anno.objectName
    //     const annotation = anno.groundTruth
    //     // const pointsWithTag = annotation.pointsWithTag
    //     annotation.forEach((element: any, index: number) => {
    //       if (element.type === 'box') {
    //         objects[objectID + '_' + index] = {
    //           frame_intervals: [{ frame_start: 0, frame_end: 0 }],
    //           name: objectID + '_' + index,
    //           object_data: {},
    //           type: objectName,
    //         }

    //         data.openlabel.openlabel.frames['0'].objects[
    //           objectID + '_' + index
    //         ] = {
    //           object_data: {
    //             boolean:
    //               element?.tags &&
    //               element.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val === 'False' || tag.val === 'True'
    //                 ),
    //             box2d: [
    //               {
    //                 attributes: {
    //                   text: [
    //                     { name: 'interpolation-method', val: 'boundingBox' },
    //                     { name: 'stream', val: 'BOSCH_FCH_FC1_JP' },
    //                   ],
    //                 },
    //                 closed: false,
    //                 mode: 'MODE_BOX2D_ABSOLUTE',
    //                 name: 'curve-4356dbf8',
    //                 x1: element?.x1,
    //                 x2: element?.x2,
    //                 y1: element?.y1,
    //                 y2: element?.y2,
    //               },
    //             ],
    //             text:
    //               element?.tags &&
    //               element?.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val !== 'False' && tag.val !== 'True'
    //                 ),
    //           },
    //         }
    //       } else if (element.type === 'polygon') {
    //         objects[objectID + '_' + index] = {
    //           frame_intervals: [{ frame_start: 0, frame_end: 0 }],
    //           name: objectID + '_' + index,
    //           object_data: {},
    //           type: objectName,
    //         }

    //         data.openlabel.openlabel.frames['0'].objects[
    //           objectID + '_' + index
    //         ] = {
    //           object_data: {
    //             boolean:
    //               element?.tags &&
    //               element?.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val === 'False' || tag.val === 'True'
    //                 ),
    //             poly2d: [
    //               {
    //                 attributes: {
    //                   text: [
    //                     { name: 'interpolation-method', val: element.type },
    //                     { name: 'stream', val: 'BOSCH_FCH_FC1_JP' },
    //                   ],
    //                 },
    //                 closed: false,
    //                 mode: 'MODE_POLY2D_ABSOLUTE',
    //                 name: 'curve-4356dbf8',
    //                 val: element.points.flatMap((point: any) => [
    //                   point.x,
    //                   point.y,
    //                 ]),
    //               },
    //             ],
    //             text:
    //               element?.tags &&
    //               element.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val !== 'False' && tag.val !== 'True'
    //                 ),
    //           },
    //         }
    //       } else if (element.type === 'polyline-bs') {
    //         const pointsWithTag = element.pointsWithTag
    //         objects[objectID + '_' + index] = {
    //           frame_intervals: [{ frame_start: 0, frame_end: 0 }],
    //           name: objectID + '_' + index,
    //           object_data: {},
    //           type: objectName,
    //         }

    //         data.openlabel.openlabel.frames['0'].objects[
    //           objectID + '_' + index
    //         ] = {
    //           object_data: {
    //             boolean:
    //               element?.tags &&
    //               element?.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val === 'False' || tag.val === 'True'
    //                 ),
    //             poly2d: [
    //               {
    //                 attributes: {
    //                   text: [
    //                     { name: 'interpolation-method', val: element.type },
    //                     { name: 'stream', val: 'BOSCH_FCH_FC1_JP' },
    //                   ],
    //                 },
    //                 closed: false,
    //                 mode: 'MODE_POLY2D_ABSOLUTE',
    //                 name: 'curve-4356dbf8',
    //                 val: element.points.flatMap((point: any) => [
    //                   point.x,
    //                   point.y,
    //                 ]),
    //               },
    //             ],
    //             text:
    //               element?.tags &&
    //               element.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val !== 'False' && tag.val !== 'True'
    //                 ),
    //           },
    //         }
    //         for (let i = 0; i < pointsWithTag.length; i++) {
    //           if (
    //             pointsWithTag[i].tags.length === 0 ||
    //             pointsWithTag[i].tags[0] === null
    //           )
    //             continue // Boş nesneleri kontrol et
    //           const pointID = `${objectID}_${index}_point${i}`
    //           objects[pointID] = {
    //             frame_intervals: [{ frame_start: 0, frame_end: 0 }],
    //             name: pointID,
    //             object_data: {},
    //             type: 'Point_2D',
    //           }
    //         }

    //         const pointsWithTags = element.pointsWithTag
    //         for (let i = 0; i < pointsWithTags.length; i++) {
    //           const pointWithTag = pointsWithTags[i]
    //           if (
    //             pointsWithTags[i].tags.length === 0 ||
    //             pointsWithTags[i].tags[0] === null
    //           )
    //             continue // Boş nesneleri kontrol et
    //           const pointID = `${objectID}_${index}_point${i}`
    //           const point = anno.groundTruth[index].points[i]
    //           console.log(pointID, imageObject.id, point)
    //           try {
    //             data.openlabel.openlabel.frames['0'].objects[pointID] = {
    //               object_data: {
    //                 point2D: [
    //                   {
    //                     attributes: {
    //                       text: [{ name: 'stream', val: 'BOSCH_FCH_FC1_JP' }],
    //                     },
    //                     name: 'point-number',
    //                     val: [point.x, point.y],
    //                   },
    //                 ],
    //                 text: pointWithTag.tags.map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 })),
    //               },
    //             }
    //           } catch (e) {
    //             console.log(e)
    //           }
    //         }
    //       } else if (element.type === 'point') {
    //         objects[objectID + '_' + index] = {
    //           frame_intervals: [{ frame_start: 0, frame_end: 0 }],
    //           name: objectID + '_' + index,
    //           object_data: {},
    //           type: objectName,
    //         }

    //         data.openlabel.openlabel.frames['0'].objects[
    //           objectID + '_' + index
    //         ] = {
    //           object_data: {
    //             boolean:
    //               element?.tags &&
    //               element?.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val === 'False' || tag.val === 'True'
    //                 ),
    //             point2d: [
    //               {
    //                 attributes: {
    //                   text: [
    //                     { name: 'interpolation-method', val: element.type },
    //                     { name: 'stream', val: 'BOSCH_FCH_FC1_JP' },
    //                   ],
    //                 },
    //                 closed: false,
    //                 mode: 'MODE_POINT2D_ABSOLUTE',
    //                 name: 'curve-4356dbf8',
    //                 x: element.x,
    //                 y: element.y,
    //               },
    //             ],
    //             text:
    //               element?.tags &&
    //               element.tags
    //                 .map((tag: any) => ({
    //                   name: tag.category,
    //                   val: tag.value.split('__')[1],
    //                 }))
    //                 .filter(
    //                   (tag: any) => tag.val !== 'False' && tag.val !== 'True'
    //                 ),
    //           },
    //         }
    //       }
    //     })
    //   }
    //   data.openlabel.openlabel = {
    //     ...data.openlabel.openlabel,
    //     objects,
    //   }
    //   const relations: any = {
    //     streams: {
    //       BOSCH_FCH_FC1_JP: {
    //         description: '',
    //         stream_properties: {
    //           height: imageObject.sizes.height,
    //           width: imageObject.sizes.width,
    //         },
    //         type: 'camera',
    //       },
    //     },
    //     tags: {},
    //   }
    //   let counter = 0
    //   let indexCounter = 0
    //   for (const key in objects) {
    //     if (key.includes('_point')) {
    //       const parentKey = key.split('_')[0]
    //       const parentIndx = parseInt(key.split('_')[1])
    //       if (
    //         !relations[parentKey + parentIndx] ||
    //         indexCounter !== parentIndx
    //       ) {
    //         relations[parentKey + parentIndx] = {
    //           name: counter.toString(),
    //           rdf_objects: [
    //             { type: 'object', uid: parentKey + '_' + parentIndx },
    //           ],
    //           rdf_subjects: [],
    //           type: 'BelongsTo',
    //         }
    //         counter++
    //         indexCounter = parentIndx
    //       }
    //       relations[parentKey + parentIndx].rdf_subjects.push({
    //         type: 'object',
    //         uid: key,
    //       })
    //     }
    //   }
    //   const updatedRelations: any = {
    //     streams: relations.streams,
    //     tags: relations.tags,
    //   }
    //   for (const key in relations) {
    //     if (key === 'streams' || key === 'tags') continue
    //     const nameValue = relations[key].name
    //     updatedRelations[nameValue] = { ...relations[key] }
    //   }

    //   data.openlabel.openlabel = {
    //     ...data.openlabel.openlabel,
    //     relations: updatedRelations,
    //   }
    //   dataExport.push(data)

    //   console.log(dataExport)
    // }
    // let data2
    // let metaTagCount: any = {}

    // for (const imageObject of imagesObject) {
    //   let data: any = {
    //     _frame_id: imageObject.id,
    //   }
    //   console.log(imageObject.tags)
    //   imageObject.tags.forEach((tag: any) => {
    //     metaTagCount[tag.value]
    //       ? metaTagCount[tag.value]++
    //       : (metaTagCount[tag.value] = 1)
    //   })
    //   console.log(metaTagCount)
    //   // const docsQuery = query(
    //   //   collectionGroup(store, 'objects'),
    //   //   where('projectID', '==', '598852_polyline-bs'),
    //   //   where('imageID', '==', imageObject.id)
    //   // )
    //   // const docsSnap = await getDocs(docsQuery)
    //   // const annotations = docsSnap.docs.map(doc => ({
    //   //   id: doc.id,
    //   //   ...doc.data(),
    //   // }))

    //   // const entityCount: any = {}
    //   // let pointCount: any = {}
    //   // const objects2: any = []
    //   // let pointTagCount: any = {}
    //   // let objectTagCount: any = {}

    //   // for (const item of annotations) {
    //   //   const anno: any = item
    //   //   if (!anno.groundTruth) continue
    //   //   const objectID = anno.id
    //   //   const objectName = anno.objectName
    //   //   const objectCount = anno.objectCount
    //   //   entityCount[objectID] = objectCount

    //   //   const annotation = anno.groundTruth

    //   //   // const pointsWithTag = annotation.pointsWithTag
    //   //   annotation.forEach((element: any, index: number) => {
    //   //     if (element.type === 'box') {
    //   //     } else if (element.type === 'polygon') {
    //   //     } else if (element.type === 'polyline-bs') {
    //   //       const pointsWithTags = element.pointsWithTag
    //   //       for (let i = 0; i < pointsWithTags.length; i++) {
    //   //         const pointWithTag = pointsWithTags[i]
    //   //         if (
    //   //           pointsWithTags[i].tags.length === 0 ||
    //   //           pointsWithTags[i].tags[0] === null
    //   //         )
    //   //           continue // Boş nesneleri kontrol et
    //   //         pointCount[imageObject.id]
    //   //           ? pointCount[imageObject.id]++
    //   //           : (pointCount[imageObject.id] = 1)
    //   //         const pointID = `${objectID}_${index}_point${i}`
    //   //         const point = anno.groundTruth[index].points[i]
    //   //         pointWithTag.tags.forEach((tag: any) => {
    //   //           pointTagCount[tag.value]
    //   //             ? pointTagCount[tag.value]++
    //   //             : (pointTagCount[tag.value] = 1)
    //   //         })
    //   //       }

    //   //       const objectWithTags = element.tags

    //   //       element.tags?.forEach((tag: any) => {
    //   //         objectTagCount[tag.value]
    //   //           ? objectTagCount[tag.value]++
    //   //           : (objectTagCount[tag.value] = 1)
    //   //       })
    //   //       // for (let i = 0; i < objectWithTags.length; i++) {
    //   //       //   const objectWithTag = objectWithTags[i]
    //   //       //   // if (
    //   //       //   //   objectWithTags[i].tags.length === 0 ||
    //   //       //   //   objectWithTags[i].tags[0] === null
    //   //       //   // )
    //   //       //   //   continue // Boş nesneleri kontrol et
    //   //       //   const pointID = `${objectID}_${index}_point${i}`
    //   //       //   const point = anno.groundTruth[index].points[i]
    //   //       //   console.log(pointID, imageObject.id)
    //   //       //   console.log(objectWithTag)

    //   //       //   console.log(objectTagCount)
    //   //       // }
    //   //     } else if (element.type === 'point') {
    //   //     }
    //   //   })
    //   // }
    //   data = {
    //     ...data,
    //   }

    //   dataExport.push(data)

    //   const totalCounts: any = {}

    //   for (const frame of dataExport) {
    //     for (const objectName in frame.entityCount) {
    //       if (frame.entityCount.hasOwnProperty(objectName)) {
    //         const objectData = frame.entityCount[objectName]
    //         if (!totalCounts.hasOwnProperty(objectName)) {
    //           totalCounts[objectName] = 0
    //         }
    //         totalCounts[objectName] += objectData
    //       }
    //     }
    //   }
    //   const dataTotal: any = {}
    //   for (const objectName in totalCounts) {
    //     if (totalCounts.hasOwnProperty(objectName)) {
    //       dataTotal[objectName] = totalCounts[objectName]
    //     }
    //   }

    //   const totalCountsPoint: any = {}

    //   for (const frame of dataExport) {
    //     for (const objectName in frame.pointTagCount) {
    //       if (frame.pointTagCount.hasOwnProperty(objectName)) {
    //         const objectData = frame.pointTagCount[objectName]
    //         if (!totalCountsPoint.hasOwnProperty(objectName)) {
    //           totalCountsPoint[objectName] = 0
    //         }
    //         totalCountsPoint[objectName] += objectData
    //       }
    //     }
    //   }
    //   const dataTotalPoint: any = {}
    //   for (const objectName in totalCountsPoint) {
    //     if (totalCountsPoint.hasOwnProperty(objectName)) {
    //       dataTotalPoint[objectName] = totalCountsPoint[objectName]
    //     }
    //   }

    //   const totalCountsEntity: any = {}

    //   for (const frame of dataExport) {
    //     for (const objectName in frame.objectTagCount) {
    //       if (frame.objectTagCount.hasOwnProperty(objectName)) {
    //         const objectData = frame.objectTagCount[objectName]
    //         if (!totalCountsEntity.hasOwnProperty(objectName)) {
    //           totalCountsEntity[objectName] = 0
    //         }
    //         totalCountsEntity[objectName] += objectData
    //       }
    //     }
    //   }
    //   const dataTotalEntity: any = {}
    //   for (const objectName in totalCountsEntity) {
    //     if (totalCountsEntity.hasOwnProperty(objectName)) {
    //       dataTotalEntity[objectName] = totalCountsEntity[objectName]
    //     }
    //   }

    //   const totalCountsEntity2: any = {}

    //   for (const frame of dataExport) {
    //     for (const objectName in frame.pointCount) {
    //       if (frame.pointCount.hasOwnProperty(objectName)) {
    //         const objectData = frame.pointCount[objectName]
    //         if (!totalCountsEntity2.hasOwnProperty(objectName)) {
    //           totalCountsEntity2[objectName] = 0
    //         }
    //         totalCountsEntity2[objectName] += objectData
    //       }
    //     }
    //   }
    //   const dataTotalEntity2: any = {}
    //   for (const objectName in totalCountsEntity2) {
    //     if (totalCountsEntity2.hasOwnProperty(objectName)) {
    //       dataTotalEntity2[objectName] = totalCountsEntity2[objectName]
    //     }
    //   }

    //   console.log(dataExport)
    //   console.log(dataTotal)
    //   console.log(dataTotalPoint)
    //   console.log(dataTotalEntity)
    //   console.log(dataTotalEntity2, 'sss')
    //   data2 = dataTotalEntity2
    // }
    // var dataStr =
    //   'data:text/json;charset=utf-8,' +
    //   encodeURIComponent(JSON.stringify(data2))
    // var downloadAnchorNode = document.createElement('a')
    // downloadAnchorNode.setAttribute('href', dataStr)
    // downloadAnchorNode.setAttribute('download', 'boschDataPOC' + '.json')
    // document.body.appendChild(downloadAnchorNode) // required for firefox
    // downloadAnchorNode.click()
    // downloadAnchorNode.remove()
    // console.log(dataExport)

    return 'data'
  }

  async function getUsagesWithProjectIDs(projectIDs: string[]) {
    const batchSize = 30 // Maximum IN query size
    const results = []
    projectIDs = projectIDs.filter((id) => id !== undefined)
    for (let i = 0; i < projectIDs.length; i += batchSize) {
      const batch = projectIDs.slice(i, i + batchSize)

      const docsQuery = query(
        collectionGroup(store, 'usage'),
        where('projectID' as any, 'in', batch)
      )

      const docsSnap = await getDocs(docsQuery)
      results.push(
        ...docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      )
    }

    return results
  }

  async function getAnnotations(
    projectID: string,
    annotationType: string,
    sizeClass: string
  ) {
    const docsQuery = query(
      collectionGroup(store, 'objects'),
      where('projectID', '==', projectID),
      where(`crossValidated.${annotationType}`, '==', true),
      where('objectCount', '>', 0),
      where('objectName', '==', sizeClass)
    )
    const docsSnap = await getDocs(docsQuery)
    return docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  }

  async function getGuidelineRegions(projectID: string, attributes: any[]) {
    const regions: any = {}
    for (const attr of attributes) {
      const attrCollection = collection(
        store,
        'Projects',
        projectID,
        'guidelines',
        attr,
        'data'
      )
      const imageDocs = await getDocs(attrCollection)

      imageDocs.forEach((img) => {
        if (regions[attr]) {
          regions[attr].push(img.data())
        } else {
          regions[attr] = [{ ...img.data() }]
        }
      })
    }
    return regions
  }

  function listenToAnnotations(projectID: string) {
    if (!projectID) return
    if (user) {
      const addedCustomers = collection(
        store,
        'Projects',
        projectID,
        'guidelines'
      )
      return addedCustomers
    }
  }

  function listenToProjectDataset(projectID: string) {
    if (!projectID) return
    if (!user) throw new Error('You need to login first!')
    const projectDataset = collection(store, 'Projects', projectID, 'dataset')
    return projectDataset
  }

  function getAttributeAnnotation(projectID: string) {
    if (!user) return

    if (!projectID) return

    const guidelinesRef = query(
      collection(store, 'Projects', projectID, 'guidelines'),
      orderBy('createdDate', 'desc')
    )
    return guidelinesRef
  }

  async function addAttributeAnnotation(
    urls: any[] = [],
    projectID: string,
    images: any[]
  ) {
    urls.forEach((url, i) => {
      const { name } = images[i]
      const guidelineRef = doc(store, 'Projects', projectID, 'guidelines', name)
      getImageSizes(images[i], (sizes: any) => {
        setDoc(guidelineRef, {
          src: url,
          name,
          regions: [],
          sizes,
          createdDate: new Date(),
        })
      })
    })
  }

  function listenToProjectByName(projectName: string) {
    const projectRef = query(
      collection(store, 'Projects'),
      where('name', '==', projectName)
    )
    return projectRef
  }

  async function getProject(projectName: string) {
    dispatch(asyncActionStart())

    const projectsCollection = collection(store, 'Projects')

    // in case we provided the projectID
    const projectQuery = query(
      projectsCollection,
      where('projectName', '==', projectName)
    )
    const projectQuerySnapshot = await getDocs(projectQuery)

    if (projectQuerySnapshot.size > 0) {
      dispatch(asyncActionFinish())
      const projectDoc = projectQuerySnapshot.docs[0]
      return { ...projectDoc.data(), projectID: projectDoc.id }
    }

    const projectRef = doc(projectsCollection, projectName)
    const projectDoc = await getDoc(projectRef)

    if (projectDoc.exists()) {
      dispatch(asyncActionFinish())
      return { ...projectDoc.data(), projectID: projectDoc.id }
    } else {
      dispatch(asyncActionError(new Error('Project not found')))
      console.error('Project not found')
      return null
    }
  }

  async function addZipFileToStorage(
    zipFile: any,
    projectID: string,
    type: string
  ) {
    const fileName = type + '_' + zipFile.name
    const storageRef = ref(storage, `Projects/${projectID}/imports/${fileName}`)
    const uploadTask: any = uploadBytesResumable(storageRef, zipFile)
    const promisesArray = []

    uploadTask.on('state_changed', (snapshot: any) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
      if (progress === 100 && type === 'images') {
        const docRef = doc(
          collection(store, 'Projects', projectID, 'importJobs'),
          fileName
        )
        getDoc(docRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              updateDoc(docRef, {
                stepOne: true,
                isLoading: true,
                status: 'creating',
                startDateTime: serverTimestamp(),
              })
            } else {
              setDoc(docRef, {
                stepOne: true,
                isLoading: true,
                status: 'creating',
                startDateTime: serverTimestamp(),
              })
            }
          })
          .catch((error) => {
            console.error('Something error:', error)
          })
      }
    })
    promisesArray.push(uploadTask)
    return Promise.all(promisesArray)
  }

  async function setServebleTrue(projectID: string) {
    try {
      const projectRef = doc(store, 'Projects', projectID)
      await updateDoc(projectRef, {
        servable: true,
      })

      return true
    } catch (error) {
      console.error('Error getting documents: ', error)
      return false
    }
  }

  async function deleteAdminPDF(projectID: string, fileName: string) {
    // remove from storage and database
    try {
      const projectRef = doc(store, 'Projects', projectID)
      await updateDoc(projectRef, {
        guidelinePdfsAdmin: [],
      })

      const storageRef = ref(
        storage,
        `Projects/${projectID}/guidelinePDFAdmin/${fileName}`
      )

      await deleteObject(storageRef)

      return true
    } catch (error) {
      console.error('Error getting documents: ', error)
      return false
    }
  }

  async function deleteTickets(customerID: string, ticketIDs: string[]) {
    try {
      const deletePromises = ticketIDs.map((ticketID) => {
        const ticketRef = doc(
          store,
          'Customers',
          customerID,
          'tickets',
          ticketID
        )
        return delDoc(ticketRef) // Ensure deleteDoc is used correctly
      })
      await Promise.all(deletePromises)
      return true
    } catch (error) {
      console.error('Error deleting documents: ', error) // Updated error message
      return false
    }
  }

  async function addJSONFileToStorage(
    jsonFile: any,
    projectID: string,
    setProgress: any,
    type: string
  ) {
    const fileName = type + '_' + jsonFile.name
    const storageRef = ref(storage, `Projects/${projectID}/imports/${fileName}`)
    const uploadTask: any = uploadBytesResumable(storageRef, jsonFile)
    const promisesArray = []

    uploadTask.on('state_changed', (snapshot: any) => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      )
      setProgress(progress)
      if (progress === 100 && type === 'images') {
        const docRef = doc(
          collection(store, 'Projects', projectID, 'importJobs'),
          fileName
        )
        getDoc(docRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              updateDoc(docRef, {
                stepOne: true,
                isLoading: true,
                status: 'creating',
                startDateTime: serverTimestamp(),
              })
            } else {
              setDoc(docRef, {
                stepOne: true,
                isLoading: true,
                status: 'creating',
                startDateTime: serverTimestamp(),
              })
            }
          })
          .catch((error) => {
            console.error('Something error:', error)
          })
      }
    })
    promisesArray.push(uploadTask)
    return Promise.all(promisesArray)
  }

  async function addYoloZipFileToStorage(
    acceptedFile: any,
    projectData: any,
    setProgress: any
  ) {
    const zip = new JSZip()
    const zipData = await zip.loadAsync(acceptedFile)
    const promisesArray = []

    const fileEntries = Object.keys(zipData.files).filter(
      (relativePath) =>
        relativePath.endsWith('.txt') &&
        !/(__MACOSX|Thumbs\.db|Desktop\.ini)/.test(relativePath)
    )

    function findUniqueCount(arr: any) {
      const uniqueValues = new Set(arr.map((item: any) => item.split(' ')[0]))
      return uniqueValues.size
    }

    let allowedWritableBatchSize = 200
    let counter = 0
    let maxParallelBatches = 1
    let parallelBatches = 0
    let imaggs: any = []

    for (let i = 0; i < fileEntries.length; i++) {
      const relativePath = fileEntries[i]
      const txtContent = await zipData.files[relativePath].async('text')
      const yoloTXT = txtContent.trim().split('\n')
      const yoloTXTLength = findUniqueCount(yoloTXT)

      if (counter + yoloTXTLength > allowedWritableBatchSize) {
        while (parallelBatches >= maxParallelBatches) {
          await new Promise((resolve) => setTimeout(resolve, 1000))
          console.log('waiting')
        }
        if (i === fileEntries.length - 1) {
          await processBatch(imaggs, i)
          imaggs = []
          const imageID = relativePath.replace('.txt', '').split('/').pop()
          const image = {
            imageID,
            yoloTXT,
          }
          imaggs.push(image)
          await processBatch(imaggs, i)
          break
        } else {
          counter = 0
          i -= 1

          await processBatch(imaggs, i)
          // time sleep 10sn
          await new Promise((resolve) => setTimeout(resolve, 10000))
          imaggs = []
          continue
        }
      }

      counter += yoloTXTLength

      const imageID = relativePath.replace('.txt', '').split('/').pop()
      const image = {
        imageID,
        yoloTXT,
      }
      imaggs.push(image)

      if (i === fileEntries.length - 1) {
        await processBatch(imaggs, i)
        const docRef = doc(
          collection(store, 'Projects', projectData.projectID, 'importJobs'),
          'txt_' + acceptedFile?.name
        )
        setDoc(docRef, {
          zipFilePath: `Projects/${projectData.projectID}/imports/txt_${acceptedFile?.name}`,
          size: acceptedFile?.size,
          status: 'completed',
          startDateTime: serverTimestamp(),
          endDateTime: serverTimestamp(),
        })
        break
      }
    }

    async function processBatch(images: any, i: number) {
      if (i + 1 === fileEntries.length) {
        setProgress(100)
      } else {
        setProgress((((i + 1) / fileEntries.length) * 100).toFixed(0))
      }
      // for annotation project
      // let projectDataCopy = {
      //   ...projectData,
      //   projectRequirments: {
      //     ...projectData.projectRequirments,
      //     projectType: 'annotation', // direkt groundtruth yazılması istenirse annotation olmalı.
      //   },
      // }
      const masterObject = {
        projectData: projectData,
        images: images,
      }
      parallelBatches++
      const response = httpsCallable(functions, 'importYOLO', {
        timeout: 540000,
      })
      const result: any = response({ masterObject }).then((result) => {
        parallelBatches--
      })
      // .then(result => {
      //   console.log(result)
      // })
      // .catch(error => {
      //   console.log(error)
      // })
      // if (result.data.status === 'success') {
      //   console.log('success')
      //   setProgress((((i + 1) / fileEntries.length) * 100).toFixed(0))
      // } else {
      //   console.log('error')
      // }
    }

    // for (let i = 0; i < fileEntries.length; i += batchSize) {
    //   const batchFiles = fileEntries.slice(i, i + batchSize)
    //   const images: any = []
    //   let promisesArray: any = []

    //   batchFiles.forEach(relativePath => {
    //     const promise = new Promise(async (resolve: any, reject) => {
    //       try {
    //         const txtContent = await zipData.files[relativePath].async('text')
    //         const yoloTXT = txtContent.trim().split('\n')

    //         images.push({
    //           imageID: relativePath
    //             .replace('.txt', '')
    //             .split('/')
    //             .pop(),
    //           yoloTXT,
    //         })

    //         resolve()
    //       } catch (error) {
    //         console.error(`Error processing file ${relativePath}: ${error}`)
    //         reject(error)
    //       }
    //     })

    //     promisesArray.push(promise)
    //   })

    //   await Promise.all(promisesArray)
    //   let projectDataCopy = { ...projectData }
    //   projectDataCopy?.imageAnalysis && delete projectDataCopy.imageAnalysis
    //   projectDataCopy?.projectTrack?.robustnessScore &&
    //     delete projectDataCopy.projectTrack.robustnessScore

    //   const masterObject = {
    //     projectData: projectDataCopy,
    //     images,
    //   }
    //   console.log(masterObject)
    //   // Assuming there's a function 'importYOLO' that can handle the masterObject
    //   const response = httpsCallable(functions, 'importYOLO')
    //   const result = await response({ masterObject })
    //   console.log(result)
    // }
    // importYOLO  =>  masterObject
    // const response  = httpsCallable(functions, 'importYOLO')
    // const result = await response({ masterObject })

    // const batchSize = 400
    // const interval = 20000 // 20 seconds
    // let currentBatch = 0

    // async function processBatch() {
    //   const batchPromises = fileEntries
    //     .slice(currentBatch * batchSize, (currentBatch + 1) * batchSize)
    //     .map(async relativePath => {
    //       const zipEntry = zipData.files[relativePath]
    //       const textContent = await zipEntry.async('text')

    //       const storageRef = ref(
    //         storage,
    //         `Projects/${projectID}/imports/${zipEntry.name}`
    //       )
    //       await uploadString(storageRef, textContent, 'raw')
    //     })

    //   await Promise.all(batchPromises)
    //   currentBatch++
    // }

    // async function processBatches() {
    //   while (currentBatch < Math.ceil(fileEntries.length / batchSize)) {
    //     await processBatch()
    //     await new Promise(resolve => setTimeout(resolve, interval))
    //     setProgress(
    //       (currentBatch / Math.ceil(fileEntries.length / batchSize)) * 100
    //     )
    //   }
    // }
    // await processBatches()
  }

  async function deleteFileStorage(path: string) {
    const storageRef = ref(storage, path)
    await deleteObject(storageRef)
  }

  async function deleteDoc(path: string) {
    const docRef = doc(store, path)
    await delDoc(docRef)
  }

  // async function uploadDataManagementImages(
  //   images: any[],
  //   projectName: string,
  //   setImages: any,
  //   navigate: any,
  //   projectType: string,
  //   path: string,
  //   projectAnnoType: string,
  //   setImageURLs: any
  // ) {
  //   const { uid } = user
  //   let numberOfSuccessfulUpload = 0

  //   let queue = images.slice()
  //   const image = queue.shift()
  //   if (!image) {
  //     // Kuyruk boş, tüm yüklemeler tamamlandı
  //     console.log('hepsi OOKKKK!')
  //     return
  //   }

  //   const { projectID, pictureFile, objectTypes, imageName } = image

  //   const imageRef =
  //     projectAnnoType === 'MLM'
  //       ? ref(storage, `Projects/${projectID}/mlm/${pictureFile.name}`)
  //       : ref(storage, `Projects/${projectID}/dataset/${pictureFile.name}`)
  //   const metadata = {
  //     customMetadata: {
  //       userID: uid,
  //       objectTypes: objectTypes.join(','),
  //       projectID: projectID,
  //     },
  //   }
  //   const uploadTask = uploadBytesResumable(imageRef, pictureFile, metadata)
  //   uploadTask.on(
  //     'state_changed',
  //     snapshot => {
  //       const progressValue = Math.floor(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       )
  //       console.log(progressValue, imageName)
  //       const percentage = progressValue > 100 ? 100 : progressValue
  //       setImages((prev: any[]) => [
  //         ...prev.map((img: any) => {
  //           if (img.imageName === imageName) {
  //             return {
  //               ...img,
  //               percentage,
  //             }
  //           }
  //           return img
  //         }),
  //       ])
  //     },
  //     error => {
  //       console.log(error)
  //     },

  //     // eslint-disable-next-line
  //     async () => {
  // //       if (projectAnnoType === 'MLM') {
  // //         const data: any = {}
  // //         const url = await getDownloadURL(uploadTask.snapshot.ref)
  // //         data[imageName] = url
  // //         setImageURLs((prev: any) => ({ ...prev, ...data }))
  // //       }
  //       if (
  //         ++numberOfSuccessfulUpload === Object.keys(images).length &&
  //         projectAnnoType !== 'MLM'
  //       ) {
  //         updateDocument(
  //           {
  //             freeImagesCount: increment(numberOfSuccessfulUpload),
  //           },
  //           `Customers/${user.uid}`
  //         )
  //         if (projectType === 'annotation') {
  //           if (path.includes('dataManagements')) {
  //             setTimeout(() => {
  //               navigate(`/projects/${projectName}/tracking`, {
  //                 state: { from: 'uploadImages' },
  //               })
  //               navigate(0)
  //             }, 2000)
  //           } else if (path.includes('step-4')) {
  //             notify('SUCCESS', 'Images uploaded successfully!')
  //             setTimeout(() => {
  //               navigate(`/projects/create/${projectName}/step-5`)
  //               navigate(0)
  //             }, 2000)
  //           }
  //         }
  //       }
  //       await uploadDataManagementImages(
  //         queue,
  //         projectName,
  //         setImages,
  //         navigate,
  //         projectType,
  //         path,
  //         projectAnnoType,
  //         setImageURLs
  //       )
  //     }
  //   )
  // }

  async function updateProjectDescription(
    projectID: string,
    description: string,
    annotationRules = {}
  ) {
    try {
      const projectRef = doc(store, `Projects/${projectID}`)
      await updateDoc(projectRef, {
        'projectRequirments.projectDescription': description,
        'projectRequirments.annotationRules': annotationRules,
      })
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function uploadDataManagementImages(
    images: any[],
    projectName: string,
    setImages: any,
    navigate: any,
    projectType: string,
    path: string,
    projectAnnoType: string,
    setImageURLs: any
  ) {
    const { uid } = user
    let numberOfSuccessfulUpload = 0
    // deep copy images
    let _images = images.slice()
    let totalImages = images.length

    // all images avg size
    let totalSize = 0
    let avgSize = 0
    images.forEach((image) => {
      totalSize += image.pictureFile.size
    })
    avgSize = totalSize / images.length

    // setBatch size according to avg size
    let batchSize = 0
    if (avgSize < 1000000) {
      // 1MB
      batchSize = 70
    } else if (avgSize < 5000000) {
      // 5MB
      batchSize = 50
    } else {
      batchSize = 25
    }

    while (_images.length) {
      const batch: any[] = _images.splice(0, batchSize)
      await uploadBatch(batch).catch((error) => {
        console.log(error)
      })

      if (_images.length === 0) {
        if (projectAnnoType === 'MLM') {
          const projectRef = doc(store, `Projects/${batch[0].projectID}`)
          const projectDoc = await getDoc(projectRef)
          const projectData = projectDoc.data()
          const totalImageUploaded = projectData?.projectTrack
            ?.totalImageUploaded
            ? projectData.projectTrack.totalImageUploaded + totalImages
            : totalImages

          await updateDoc(projectRef, {
            'projectTrack.totalImageUploaded': totalImageUploaded,
          })
        }
        if (projectType === 'annotation') {
          if (path.includes('dataManagements')) {
            notify(
              'SUCCESS',
              'Upload Successfully! You can see your uploaded data soon in the Review tab. It may take a few minutes to process the data.'
            )
          }
        }
      }
    }

    function uploadBytesResumableAsync(ref: any, file: any, metadata: any) {
      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(ref, file, metadata)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progressValue = Math.floor(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            const percentage = progressValue > 100 ? 100 : progressValue
            setImages((prev: any[]) => [
              ...prev.map((img: any) => {
                if (img.imageName === file.name) {
                  return {
                    ...img,
                    percentage,
                  }
                }
                return img
              }),
            ])
          },
          (error) => {
            // due to access checks
            uploadTask.cancel()
            reject(error)
          },
          async () => {
            if (projectAnnoType === 'MLM') {
              const data: any = {}
              const url = await getDownloadURL(uploadTask.snapshot.ref)
              data[file.name] = url
              setImageURLs((prev: any) => ({ ...prev, ...data }))
            }
            // await 25 seconds for firebase to update.
            if (_images.length === 0) {
              await new Promise((resolve) => setTimeout(resolve, 2000))
            } else {
              await new Promise((resolve) => setTimeout(resolve, 25000))
            }
            resolve(uploadTask.snapshot)
          }
        )
      })
    }

    async function uploadBatch(batch: any) {
      await Promise.all(
        batch.map(async (image: any) => {
          const { projectID, pictureFile, objectTypes, imageName } = image

          const imageRef =
            projectAnnoType === 'MLM'
              ? ref(storage, `Projects/${projectID}/mlm/${pictureFile.name}`)
              : projectAnnoType === 'lidar'
              ? ref(
                  storage_lidar,
                  `Projects/${projectID}/archives/${pictureFile.name}`
                )
              : ref(
                  storage,
                  `Projects/${projectID}/dataset/${pictureFile.name}`
                )
          const metadata = {
            customMetadata: {
              userID: uid,
              objectTypes: objectTypes.join(','),
              projectID: projectID,
            },
          }
          const snapshot: any = await uploadBytesResumableAsync(
            imageRef,
            pictureFile,
            metadata
          )
            .then((snapshot: any) => {
              console.log('done')
            })
            .catch((error: any) => {
              console.log(error)
            })
        })
      )
    }
  }

  async function getAcceptedFiles(
    projectID: string,
    acceptedFiles: any[],
    dataType: string,
    isFrame: boolean = false
  ) {
    const imagesRef = ref(storage, `Projects/${projectID}/dataset`)
    const allImagesSnap = await listAll(imagesRef)
    let allImages: any[] = []
    if (dataType === 'video') {
      if (!isFrame) return acceptedFiles
      allImages = allImagesSnap.items.map((ref) => ref.name.split('-')[0])
      acceptedFiles = acceptedFiles.filter((file) => {
        if (!/^\d{5}-/.test(file.name)) {
          notify(
            'FAIL',
            `Invalid file name format: ${file.name}. Valid format: 00000-*.jpg`
          )
          return false
        }
        return true
      })

      acceptedFiles.forEach((file) => {
        if (allImages.includes(file.name.split('-')[0])) {
          notify(
            'FAIL',
            `Sequence id ${file.name.split('-')[0]} already exists in dataset!`
          )
        }
      })
      return acceptedFiles.filter(
        (file) => !allImages.includes(file.name.split('-')[0])
      )
    } else {
      allImages = allImagesSnap.items.map((ref) => ref.name)
      acceptedFiles.forEach((file) => {
        if (allImages.includes(file.name)) {
          notify('FAIL', `Image ${file.name} already exists in dataset!`)
        }
      })
      return acceptedFiles.filter((file) => !allImages.includes(file.name))
    }
  }

  async function uploadImagesByPath(
    images: any[],
    path: string,
    onStateChange: any,
    metadata: any = null
  ) {
    if (!user) return null
    const prePath = 'Projects'
    const promisesArray = []
    for (const image of images) {
      const { name } = image
      const imageRef = ref(storage, `${prePath}/${path}/${name}`)
      const uploadTask = uploadBytesResumable(imageRef, image, metadata)
      uploadTask.on(
        'state_changed',
        onStateChanged(dispatch, onStateChange, firebase, name),
        onError
      )
      promisesArray.push(uploadTask)
    }
    return Promise.all(promisesArray)
  }

  async function getFileUrlByPath(path: string) {
    const storageRef = ref(storage, path)
    const url = await getDownloadURL(storageRef)
    return url
  }

  // Legacy name: uploadImages2
  async function uploadGuidelineImages(
    images: any[],
    projectID: string,
    setImages?: any,
    handleBack?: any,
    afterFinished?: any
  ) {
    let numberOfSuccessfulUpload = 0

    for (const image of images) {
      const imageRef = ref(
        storage,
        `Projects/${projectID}/guidelines/${image.name}`
      )
      const uploadTask = uploadBytesResumable(imageRef, image)
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progressValue = Math.floor(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          )
          const percentage = progressValue > 100 ? 100 : progressValue
          setImages((prev: any[]) => [
            ...prev.map((img: any) => {
              if (img.name === image.name) {
                return {
                  ...img,
                  percentage,
                }
              }
              return img
            }),
          ])
        },
        (error) => {
          console.log(error)
        },
        // eslint-disable-next-line
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref)
          getImageSizes(image, async (sizes: any) => {
            await setDoc(
              doc(store, `Projects/${projectID}/guidelines/${image.name}`),
              {
                src: url,
                name: image.name,
                regions: [],
                sizes,
                createdDate: Date.now(),
              }
            )
          })
          if (++numberOfSuccessfulUpload === images.length) {
            // Update isSampleDataUploaded to true
            await updateDoc(doc(store, `Projects/${projectID}`), {
              'projectTrack.isSampleDataUploaded': true,
            })

            handleBack && handleBack()
            afterFinished && afterFinished()
          }
        }
      )
    }
  }

  async function getGuidelineImages(projectID: string) {
    try {
      const datasetRef = collection(store, 'Projects', projectID, 'guidelines')

      const documentSnapshots = await getDocs(datasetRef)

      if (!documentSnapshots.empty) {
        const images = documentSnapshots.docs.map((doc) => doc.data())
        return images
      }
      return []
    } catch (error) {
      console.log(error)
    }
  }

  async function getGuidelineTextData(projectID: string) {
    try {
      const datasetRef = query(
        collection(store, 'Projects', projectID, 'guidelines'),
        orderBy('createdAt', 'asc')
      )

      const documentSnapshots = await getDocs(datasetRef)

      if (!documentSnapshots.empty) {
        const textData = documentSnapshots.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
        return textData
      }
      return []
    } catch (error) {
      console.log(error)
    }
  }

  async function uploadFileProfile(
    file: File,
    path: string,
    metadata: any = null
  ) {
    const imageRef = ref(storage, path)
    const snapshot = await uploadBytes(imageRef, file, metadata)
    const url = await getDownloadURL(snapshot.ref)
    return url
  }

  function getFilesDataFromDataset(projectID: string) {
    if (!user) return
    return collection(store, 'Projects', projectID, 'dataset')
  }

  async function updateDocument(data: any, documentPath: string) {
    if (!documentPath) return
    const response = await updateDoc(doc(store, documentPath), data)
    return response
  }

  async function deleteDocument(documentPath: string) {
    if (!documentPath) return
    const response = await delDoc(doc(store, documentPath))
    return response
  }
  // async function updateDocument(data: any, documentPath: string) {
  //   if (!documentPath) return

  //   const docRef = doc(store, documentPath)

  //   if (await getDoc(docRef).then(doc => doc.exists())) {
  //     await updateDoc(docRef, data)
  //     console.log('Belge güncellendi!')
  //   } else {
  //     await setDoc(docRef, data)
  //     console.log('Yeni belge oluşturuldu!')
  //   }
  // }

  function pointToCollection(collectionPath: string) {
    if (!user) return
    const projectsRef = collection(store, collectionPath)
    return projectsRef
  }

  async function sendEmailResetPassword(email: string) {
    try {
      await sendPasswordResetEmail(auth, email)
      return 'success'
    } catch (error) {
      const _error = error as any
      return _error.code
    }
  }

  async function updateCustomerFirstOnboarding(firstOnboarding: boolean) {
    if (!user) return
    const response = await updateDocument(
      { firstOnboarding },
      `Customers/${user.uid}`
    )
    return response
  }

  // in customer subcollection subscriptions, with activeSub id retrieve the subcollection invoices

  async function getSubscriptionInvoices(activeSubID: string) {
    if (!user) return
    const invoicesRef = collection(
      store,
      'Customers',
      user.uid,
      'subscriptions',
      activeSubID,
      'invoices'
    )
    const q = query(invoicesRef, orderBy('created', 'desc'))
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((doc) => doc.data())
  }

  async function updateCustomerIsUnread(notifID: string) {
    if (!user) return
    const response = await updateDocument(
      { [`receivers.${user.uid}`]: false },
      `CDNotifications/${notifID}`
    )
    return response
  }

  async function updateCustomerSubscriptionStatus(subscriptionStatus: string) {
    if (!user) return
    const response = await updateDocument(
      { subscriptionStatus },
      `Customers/${user.uid}`
    )
    return response
  }

  async function updateCustomerSubscriptionPeriod(subscriptionPeriod: string) {
    if (!user) return
    const response = await updateDocument(
      { subscriptionPeriod },
      `Customers/${user.uid}`
    )
    return response
  }

  async function updateCustomerSubscriptionType(subscriptionType: string) {
    if (!user) return
    const response = await updateDocument(
      { subscriptionType, subscriptionStatus: 'active' },
      `Customers/${user.uid}`
    )
    return response
  }

  async function updateProfile(profile: any) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        profile,
      },
      { merge: true }
    )
    return response
  }

  async function upadateDashboardSettings(access: boolean) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        dashboardSettings: {
          notifications: access,
        },
      },
      { merge: true }
    )
    return response
  }

  async function upadateDashboardSettingsEmail(access: boolean) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        dashboardSettings: {
          weeklyEmail: access,
        },
      },
      { merge: true }
    )
    return response
  }

  async function updateProfilePic(profilePicURL: string) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        profile: {
          profilePicURL,
        },
      },
      { merge: true }
    )
    return response
  }
  async function updateMLMGuidelineImage(imageURL: string, projectID: string) {
    if (!user) return
    const datasetRef = query(
      collection(store, 'Projects', projectID, 'guidelines'),
      orderBy('createdAt', 'asc')
    )

    const documentSnapshots = await getDocs(datasetRef)

    if (!documentSnapshots.empty) {
      const textData = documentSnapshots.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      const response = await updateDoc(
        doc(store, `Projects/${projectID}/guidelines/${textData[0].id}`),
        {
          imageURL,
        }
      )
      return response
    }
  }

  async function updateCompanyProfile(companyProfile: string) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        profile: {
          companyProfile,
        },
      },
      { merge: true }
    )
    return response
  }

  async function updateCompanyLogo(LogoURL: string) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        profile: {
          LogoURL,
        },
      },
      { merge: true }
    )
    return response
  }

  async function updateProfileInAllProjects(userProfileURL: string) {
    const projetcsRef = collection(store, 'Projects')
    const projectsQ = query(
      projetcsRef,
      where(`projectOwner.id`, '==', user.uid)
    )
    const projects = await getDocs(projectsQ)
    projects.forEach((project) => {
      const projectRef = doc(store, 'Projects', project.id)
      updateDoc(projectRef, {
        projectOwner: {
          ...project.data().projectOwner,
          userProfileURL,
        },
      })
    })
  }

  async function updateCustomerWelcomeMessage(welcomeMessage: string) {
    if (!user) return
    const response = await setDoc(
      doc(store, 'Customers', user.uid),
      {
        welcomeMessage,
      },
      { merge: true }
    )
    return response
  }

  async function checkoutSessionRef(prices: any) {
    const response = await addDoc(
      collection(store, 'Customers', user.uid, 'checkout_sessions'),
      {
        line_items: prices,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }
    )
    return response
  }

  async function getExportDatas(projectID: string) {
    const attrsQuery = query(
      collectionGroup(store, 'objects'),
      where('projectID', '==', projectID),
      where(`groundTruth`, '!=', null)
    )
    const attrsSnapshot = await getDocs(attrsQuery)
    const attrsList = attrsSnapshot.docs.map((doc) => doc.data())

    const imagesRef = collection(store, 'Projects', projectID, 'dataset')
    const imagesSnap = await getDocs(imagesRef)
    const images = imagesSnap.docs.map((doc) => {
      const img = doc.data()
      return {
        imageID: img.imageID,
        sizes: img.sizes,
        annotations: attrsList
          .filter((attr) => attr.imageID === img.imageID)
          .map((attr) => ({
            groundTruth: attr.groundTruth,
            objectName: attr.objectName,
          })),
      }
    })
    return images.filter((img) => img.annotations.length !== 0)
  }

  async function getReviewTextDataEvaluation(
    setLoading: any,
    projectData: any,
    lastVisibleDoc: any,
    setLastVisibleDoc: any
  ) {
    try {
      setLoading(true)

      const datasetRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset'
      )
      let queryRef
      let datasetSnapshot

      if (lastVisibleDoc === null) {
        // Query the first page of docs
        queryRef = query(datasetRef, orderBy('timestamp', 'asc'), limit(100))
        datasetSnapshot = await getDocs(queryRef)
      } else {
        // Construct a new query starting at this document, get the next 100 docs
        queryRef = query(
          datasetRef,
          orderBy('timestamp', 'asc'),
          startAfter(lastVisibleDoc),
          limit(100)
        )
        datasetSnapshot = await getDocs(queryRef)
      }

      // Get the last visible document
      const lastVisible = datasetSnapshot.docs[datasetSnapshot.docs.length - 1]

      // Update the last visible document
      setLastVisibleDoc(lastVisible)

      const datasetData = datasetSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      return {
        data: datasetData,
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getReviewTextDatas(setLoading: any, projectData: any) {
    try {
      setLoading(true)
      const datasetRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset'
      )
      const datasetSnapshot = await getDocs(datasetRef)
      const datasetData = datasetSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      return datasetData
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateFineTunedPrompt(
    projectID: string,
    promptID: string,
    newFineTuned: string
  ): Promise<any> {
    try {
      const promptRef = doc(store, 'Projects', projectID, 'dataset', promptID)
      await updateDoc(promptRef, {
        fineTuned: newFineTuned,
      })

      // Fetch the updated document
      const updatedDoc = await getDoc(promptRef)
      if (updatedDoc.exists()) {
        return updatedDoc.data()
      } else {
        throw new Error('Document does not exist')
      }
    } catch (error) {
      console.error('Error updating document: ', error)
      throw error
    }
  }
  async function updateTextDatas(
    setUploading: any,
    projectData: any,
    data: any
  ) {
    try {
      setUploading(true)
      const projectDocRef = doc(store, 'Projects', projectData.projectID)

      // Her bir özellik için güncelleme işlemlerini oluştur
      const updatePromises = Object.entries(data['classesCount']).map(
        async ([key, value]: any) => {
          const updatePath = `annotationTrack.${key}`
          await updateDoc(projectDocRef, {
            [updatePath]: value,
          })
        }
      )
      console.log(data['classesCount'])
      await updateDoc(projectDocRef, {
        [`evaluationAnalysis.evaulationMatchedCount`]: data['matched_count'],
        [`evaluationAnalysis.evaulationUncertaintyCount`]:
          data['uncertainty_count'],
        [`evaluationAnalysis.splittedConfidentScore`]:
          data['splittedConfidentScore'],
        [`evaluationAnalysis.minScore`]: data['minScore'],
        [`evaluationAnalysis.maxScore`]: data['maxScore'],
        [`evaluationAnalysis.averageScore`]: data['averageScore'],
      })

      // Tüm güncelleme işlemlerinin tamamlanmasını bekleyin
      await Promise.all(updatePromises)

      console.log('Güncelleme işlemleri tamamlandı.')
      return 1
    } catch (error) {
      console.error('Veri güncelleme hatası:', error)
      return 0
    } finally {
      setUploading(false)
    }
  }

  async function getTasks(setLoading: any, projectID: string) {
    try {
      setLoading(true)
      const tasksRef = collection(store, 'Projects', projectID, 'tasks')
      const tasksSnapshot = await getDocs(tasksRef)
      const tasksData = tasksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      return tasksData
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function updateTask(taskID: string, projectID: string, data: any) {
    try {
      const docRef = doc(store, 'Projects', projectID, 'tasks', taskID)
      await updateDoc(docRef, data)
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function deleteTask(taskID: string, projectID: string) {
    try {
      await delDoc(doc(store, 'Projects', projectID, 'tasks', taskID))
      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function getAnnotators(setLoading: any, companyName: string) {
    try {
      setLoading(true)
      // get all annotators from the company
      const annotatorsRef = collection(store, 'WatUsers')
      const annotatorsQuery = query(
        annotatorsRef,
        where('companyName', '==', companyName)
      )
      const annotatorsSnapshot = await getDocs(annotatorsQuery)
      const annotatorsData = annotatorsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      return annotatorsData
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function removeUserFromWatUsers(id: string) {
    try {
      await delDoc(doc(store, 'WatUsers', id))
      return true
    } catch (error) {
      console.log(error)
    }
  }

  async function getReviewImages(
    setLoading: any,
    setTotalImages: any,
    setLastDocument: any,
    projectData: any,
    IMAGE_LIMIT: number,
    batchSize: number = 25
  ) {
    try {
      setTotalImages(projectData.projectTrack.totalData)
      if (projectData.projectRequirments.dataType === 'video') {
        IMAGE_LIMIT = IMAGE_LIMIT * batchSize
        setTotalImages(
          (projectData.projectTrack.totalData / batchSize).toFixed(0)
        )
      }
      setLoading(true)
      const datasetRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset'
      )
      const datasetQuery = query(datasetRef, limit(IMAGE_LIMIT))
      const datasetSnapshot = await getDocs(datasetQuery)

      if (datasetSnapshot.empty) return []

      setLastDocument(datasetSnapshot.docs[datasetSnapshot.docs.length - 1])
      const imageDatas = datasetSnapshot.docs.map((doc) => doc.data())
      let boyutluListe = []
      if (projectData.projectRequirments.dataType === 'video') {
        for (let i = 0; i < imageDatas.length; i += batchSize) {
          boyutluListe.push(imageDatas.slice(i, i + batchSize))
        }
        return boyutluListe
      }
      return imageDatas
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getImagesInRange(
    setImageLoading: any,
    range: string,
    projectData: any,
    batchSize: number = 25
  ) {
    try {
      if (!range) return []
      setImageLoading(true)
      const [start, end] = range[0].split('-').map(Number)
      const IMAGE_LIMIT = end - start + 1

      const datasetRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset'
      )
      const first = query(
        datasetRef,
        orderBy('createdDate', 'asc'),
        limit(start + 1)
      )

      const documentSnapshots = await getDocs(first)
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1]
      const next = query(
        datasetRef,
        orderBy('createdDate', 'asc'),
        startAfter(lastVisible),
        limit(IMAGE_LIMIT)
      )

      const datasetSnapshot = await getDocs(next)

      if (datasetSnapshot.empty) return []

      const imageDatas = datasetSnapshot.docs.map((doc) => doc.data())
      return imageDatas
    } catch (error) {
      console.log(error)
    } finally {
      setImageLoading(false)
    }
  }

  async function getReviewMoreImages(
    setLoading: any,
    setLastDocument: any,
    lastDocument: any,
    projectData: any,
    IMAGE_LIMIT: number,
    batchSize: number = 25
  ) {
    try {
      if (projectData.projectRequirments.dataType === 'video') {
        IMAGE_LIMIT = IMAGE_LIMIT * batchSize
      }
      setLoading(true)
      const datasetRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset'
      )
      const datasetQuery = query(
        datasetRef,
        startAfter(lastDocument),
        limit(IMAGE_LIMIT)
      )
      const datasetSnapshot = await getDocs(datasetQuery)
      if (datasetSnapshot.empty) return []

      setLastDocument(datasetSnapshot.docs[datasetSnapshot.docs.length - 1])
      const imageDatas = datasetSnapshot.docs.map((doc) => doc.data())
      let boyutluListe = []
      if (projectData.projectRequirments.dataType === 'video') {
        for (let i = 0; i < imageDatas.length; i += batchSize) {
          boyutluListe.push(imageDatas.slice(i, i + batchSize))
        }
        return boyutluListe
      }
      return imageDatas
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getReviewFilteredImages(
    setLoading: any,
    setTotalImages: any,
    setLastDocument: any,
    lastDocument: any,
    setObjectsLastDocument: any,
    customMenuItems: any[],
    menuItems: any[],
    projectData: any,
    IMAGE_LIMIT: number,
    pageIndex: number,
    batchSize: number = 25
  ) {
    try {
      setLoading(true)
      const choosenAttributes = customMenuItems.filter((item) => item.checked)
      const choosenFilter = menuItems.find((item) => item.checked)
      if (!choosenAttributes.length) {
        if (choosenFilter) {
          let datasetQuery: any
          let allDatasetQuery: any
          const datasetRef = collection(
            store,
            'Projects',
            projectData.projectID,
            'dataset'
          )
          switch (choosenFilter?.label) {
            case 'All':
              const _images = await getReviewImages(
                setLoading,
                setTotalImages,
                setLastDocument,
                projectData,
                IMAGE_LIMIT,
                batchSize
              )
              return _images
            case 'Completed':
              allDatasetQuery = query(
                datasetRef,
                where(
                  `crossValidated.${projectData.projectRequirments.annotationType}`,
                  '==',
                  true
                )
              )
              if (projectData.projectRequirments.dataType === 'video') {
                IMAGE_LIMIT = IMAGE_LIMIT * batchSize
              }
              datasetQuery = query(
                datasetRef,
                where(
                  `crossValidated.${projectData.projectRequirments.annotationType}`,
                  '==',
                  true
                ),
                limit(IMAGE_LIMIT)
              )
              break
            case 'Incompleted':
              allDatasetQuery = query(
                datasetRef,
                where(
                  `crossValidated.${projectData.projectRequirments.annotationType}`,
                  '==',
                  false
                )
              )
              if (projectData.projectRequirments.dataType === 'video') {
                IMAGE_LIMIT = IMAGE_LIMIT * batchSize
              }
              datasetQuery = query(
                datasetRef,
                where(
                  `crossValidated.${projectData.projectRequirments.annotationType}`,
                  '==',
                  false
                ),
                limit(IMAGE_LIMIT)
              )
              break
            case 'Uncertain':
              // allDatasetQuery = query(datasetRef, where(`isReview`, '==', true));
              // datasetQuery = query(datasetRef, where(`isReview`, '==', true), limit(IMAGE_LIMIT));
              return []
            default:
              break
          }
          const datasetSnapshot = await getDocs(datasetQuery)
          const allDatasetSnapshot = await getCountFromServer(allDatasetQuery)
          setTotalImages(allDatasetSnapshot.data().count)

          if (datasetSnapshot.empty) return []

          setLastDocument(datasetSnapshot.docs[datasetSnapshot.docs.length - 1])
          const imageDatas: any = datasetSnapshot.docs.map((doc) => doc.data())
          if (projectData.projectRequirments.dataType === 'video') {
            const batches = []
            let current_batch: any[] = []
            let current_batch_id = parseInt(imageDatas[0].imageID.split('-')[0])
            let batch_start_id = parseInt(imageDatas[0].imageID.split('-')[0])
            let bolis = false

            for (const image of imageDatas) {
              const imageID = parseInt(image.imageID.split('-')[0])

              if (
                imageID < batch_start_id + batchSize &&
                current_batch.length + 1 <= batchSize
              ) {
                current_batch.push(image)
                current_batch_id++

                if (current_batch_id % batchSize === 1) {
                  batches.push([...current_batch])
                  current_batch = []
                  batch_start_id = current_batch_id
                }
              } else if (imageID % batchSize === 0) {
                if (bolis) {
                  batches.push([...current_batch])
                  current_batch = []
                  bolis = false
                }
                batch_start_id = imageID + 1
                current_batch.push(image)
                current_batch_id = imageID + 1
                batches.push([...current_batch])
                current_batch = []
              } else {
                if (current_batch.length > 0) {
                  batches.push([...current_batch])
                  current_batch = []
                }
                batch_start_id = imageID - (imageID % batchSize) + 1
                current_batch.push(image)
                current_batch_id = imageID - (imageID % batchSize) + 1
                bolis = true
              }
            }

            if (current_batch.length > 0) {
              batches.push([...current_batch])
            }
            setTotalImages(batches.length)

            return batches
          }

          return imageDatas
        }
      } else {
        let attrsQuery: any
        switch (choosenFilter?.label) {
          case 'All':
            const _images = await getReviewImages(
              setLoading,
              setTotalImages,
              setLastDocument,
              projectData,
              IMAGE_LIMIT
            )
            return _images
          case 'Completed':
            attrsQuery = query(
              collectionGroup(store, 'objects'),
              where('projectID', '==', projectData.projectID),
              where(
                'objectName',
                'in',
                choosenAttributes.map((ca) => ca.label)
              ),
              where(`groundTruth`, '!=', null)
            )
            break
          case 'Incompleted':
            attrsQuery = query(
              collectionGroup(store, 'objects'),
              where('projectID', '==', projectData.projectID),
              where(
                'objectName',
                'in',
                choosenAttributes.map((ca) => ca.label)
              ),
              where(`groundTruth`, '==', null)
            )
            break
          case 'Uncertain':
            attrsQuery = query(
              collectionGroup(store, 'objects'),
              where('projectID', '==', projectData.projectID),
              limit(1)
            )
            break
          default:
            break
        }
        const attrsSnapshot2 = await getCountFromServer(attrsQuery)

        if (pageIndex === 0 && choosenAttributes.length < 2) {
          attrsQuery = query(
            collectionGroup(store, 'objects'),
            where('projectID', '==', projectData.projectID),
            where('groundTruth', '!=', null),
            where(
              'objectName',
              'in',
              choosenAttributes.map((ca) => ca.label)
            ),
            limit(IMAGE_LIMIT)
          )
          setTotalImages(attrsSnapshot2.data().count)
        } else if (pageIndex === 0 && choosenAttributes.length > 1) {
          attrsQuery = query(
            collectionGroup(store, 'objects'),
            where('projectID', '==', projectData.projectID),
            where('groundTruth', '!=', null),
            where(
              'objectName',
              'in',
              choosenAttributes.map((ca) => ca.label)
            )
          )
        }
        const attrsSnapshot = await getDocs(attrsQuery)
        const attrsList = attrsSnapshot.docs.map((doc) => doc.data())

        const idToAttrs: any = {}
        attrsList.forEach((attr: any) => {
          if (idToAttrs[attr.imageID]) {
            idToAttrs[attr.imageID].push(attr.objectName)
          } else {
            idToAttrs[attr.imageID] = [attr.objectName]
          }
        })

        const imageIDList: any[] = []
        const entries: any[] = Object.entries(idToAttrs)
        for (const [id, attrs] of entries) {
          if (
            choosenAttributes
              .map((ca) => ca.label)
              .every((choosenAttr: any) => attrs.includes(choosenAttr))
          ) {
            imageIDList.push(id)
          }
        }

        const chunks = []
        for (let i = 0; i < imageIDList.length; i += 30) {
          chunks.push(imageIDList.slice(i, i + 30))
        }
        let imagesSnapshot: any = []
        for (const chunk of chunks) {
          const snapshot = await getDocs(
            query(
              collection(store, 'Projects', projectData.projectID, 'dataset'),
              where('imageID', 'in', chunk)
            )
          )
          imagesSnapshot.push(...snapshot.docs)
        }
        const imageList = imagesSnapshot.map((doc: any) => doc.data())
        if (choosenAttributes.length > 1) {
          setTotalImages(imageList.length)
        }
        setObjectsLastDocument(
          attrsSnapshot.docs[attrsSnapshot.docs.length - 1]
        )
        setLastDocument(imagesSnapshot[imagesSnapshot.length - 1])
        return imageList
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getReviewMoreFilteredImages(
    setLoading: any,
    setLastDocument: any,
    lastDocument: any,
    setObjectsLastDocument: any,
    objectsLastDocument: any,
    customMenuItems: any[],
    menuItems: any[],
    projectData: any,
    IMAGE_LIMIT: number,
    pageIndex: number,
    batchSize: number = 25
  ) {
    try {
      setLoading(true)
      const choosenAttributes = customMenuItems.filter((item) => item.checked)
      const choosenFilter = menuItems.find((item) => item.checked)
      if (!choosenAttributes.length) {
        let datasetQuery: any
        const datasetRef = collection(
          store,
          'Projects',
          projectData.projectID,
          'dataset'
        )
        switch (choosenFilter.label) {
          case 'All':
            const _images = await getReviewMoreImages(
              setLoading,
              setLastDocument,
              lastDocument,
              projectData,
              IMAGE_LIMIT
            )
            return _images
          case 'Completed':
            datasetQuery = query(
              datasetRef,
              where(
                `crossValidated.${projectData.projectRequirments.annotationType}`,
                '==',
                true
              ),
              startAfter(lastDocument),
              limit(IMAGE_LIMIT)
            )
            break
          case 'Incompleted':
            datasetQuery = query(
              datasetRef,
              startAfter(lastDocument),
              where(
                `crossValidated.${projectData.projectRequirments.annotationType}`,
                '==',
                false
              ),
              limit(IMAGE_LIMIT)
            )
            break
          case 'Uncertain':
            datasetQuery = query(
              datasetRef,
              startAfter(lastDocument),
              where(`isReview`, '==', true),
              where(`isValidated`, '==', false),
              limit(IMAGE_LIMIT)
            )
            break
          default:
            break
        }
        const datasetSnapshot = await getDocs(datasetQuery)
        if (datasetSnapshot.empty) return []

        setLastDocument(datasetSnapshot.docs[datasetSnapshot.docs.length - 1])
        const imageDatas: any = datasetSnapshot.docs.map((doc) => doc.data())
        if (projectData.projectRequirments.dataType === 'video') {
          const batches = []
          let current_batch: any[] = []
          let current_batch_id = imageDatas[0].imageID.split('-')[0]
          let batch_start_id = imageDatas[0].imageID.split('-')[0]

          for (const image of imageDatas) {
            const imageID = parseInt(image.imageID.split('-')[0])
            if (
              imageID < batch_start_id + batchSize &&
              current_batch.length + 1 <= batchSize
            ) {
              current_batch.push(image)
              current_batch_id++

              if (current_batch_id % batchSize === 1) {
                batches.push([...current_batch])
                current_batch = []
                batch_start_id = current_batch_id
              }
            } else {
              batches.push([...current_batch])
              current_batch = []
              batch_start_id = imageID
              current_batch.push(image)
              current_batch_id = imageID + 1
            }
          }

          if (current_batch.length > 0) {
            batches.push([...current_batch])
          }
          return batches
        }

        return imageDatas
      } else {
        let attrsQuery: any
        switch (choosenFilter.label) {
          case 'Completed':
            attrsQuery = query(
              collectionGroup(store, 'objects'),
              orderBy('groundTruth'),
              where('projectID', '==', projectData.projectID),
              where(`groundTruth`, '!=', null),
              where(
                'objectName',
                'in',
                choosenAttributes.map((ca) => ca.label)
              ),
              startAfter(objectsLastDocument),
              limit(IMAGE_LIMIT)
            )
            break
          default:
            break
        }
        const attrsSnapshot = await getDocs(attrsQuery)
        const attrsList = attrsSnapshot.docs.map((doc) => doc.data())

        const idToAttrs: any = {}
        attrsList.forEach((attr: any) => {
          if (idToAttrs[attr.imageID]) {
            idToAttrs[attr.imageID].push(attr.objectName)
          } else {
            idToAttrs[attr.imageID] = [attr.objectName]
          }
        })

        const imageIDList: any[] = []
        const entries: any[] = Object.entries(idToAttrs)
        for (const [id, attrs] of entries) {
          if (
            choosenAttributes
              .map((ca) => ca.label)
              .every((choosenAttr: any) => attrs.includes(choosenAttr))
          ) {
            imageIDList.push(id)
          }
        }

        const chunks = []
        for (let i = 0; i < imageIDList.length; i += 30) {
          chunks.push(imageIDList.slice(i, i + 30))
        }
        let imagesSnapshot: any = []
        for (const chunk of chunks) {
          const snapshot = await getDocs(
            query(
              collection(store, 'Projects', projectData.projectID, 'dataset'),
              where('imageID', 'in', chunk)
            )
          )
          imagesSnapshot.push(...snapshot.docs)
        }
        const imageList = imagesSnapshot.map((doc: any) => doc.data())

        setLastDocument(imagesSnapshot[imagesSnapshot.length - 1])
        setObjectsLastDocument(
          attrsSnapshot.docs[attrsSnapshot.docs.length - 1]
        )
        return imageList
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function getReviewImagesData(
    images: any[],
    projectData: any,
    isLidar: boolean
  ) {
    const promises = images.map((img) => {
      const objectsRef = collection(
        store,
        'Projects',
        projectData.projectID,
        'dataset',
        isLidar ? img.pcdID : img.imageID,
        'objects'
      )
      const queryRef = query(objectsRef, where('groundTruth', '!=', null))
      const queryData = getDocs(queryRef)
      return queryData
    })
    const objectsData = await Promise.all(promises)

    const allImages = images.map((img, i) => ({
      ...img,
      annotations: objectsData[i].docs
        .filter((doc) => doc.data()?.groundTruth)
        .map((doc) => ({
          annot: doc.data().groundTruth,
          objectName: doc.data().objectName,
        })),
    }))

    return allImages
  }

  async function getReviewRightBarData(
    image: any,
    projectData: any,
    setRightBarData: any,
    isAnnotation: boolean = false,
    isLidar: boolean = false
  ) {
    try {
      if (projectData.projectRequirments.dataType === 'image') {
        dispatch(asyncActionStart())
        const objectsRef = collection(
          store,
          'Projects',
          projectData.projectID,
          'dataset',
          isLidar ? image.pcdID : image.imageID,
          'objects'
        )
        const objectsSnapshot = await getDocs(objectsRef)
        const annot: any = {}
        const customerAnnot: any = {}

        const promises: any = objectsSnapshot.docs.map(async (doc) => {
          const { objectName, groundTruth, customerAnnotation } = doc.data()
          // get annotation collection and order by crated Date and limit 1
          if (isAnnotation) {
            const annotRef = collection(
              store,
              'Projects',
              projectData.projectID,
              'dataset',
              isLidar ? image.pcdID : image.imageID,
              'objects',
              doc.id,
              'annotations'
            )
            const annotQuery = query(
              annotRef,
              orderBy('timestamp', 'desc'),
              limit(1)
            )
            const annotSnapshot = await getDocs(annotQuery)
            const annotData = annotSnapshot.docs.map((doc) => doc.data())
            if (annotData.length) annot[objectName] = annotData[0]?.annotation
          }

          if (groundTruth && groundTruth.length) {
            annot[objectName] = groundTruth
            if (customerAnnotation?.length) {
              customerAnnot[objectName] = customerAnnotation
            } else if (customerAnnotation === null) {
              customerAnnot[objectName] = []
            }
          }
        })
        await Promise.all(promises)
        const objectData = {
          annotation: annot,
          customerAnnotation: customerAnnot,
        }
        setRightBarData({
          ...objectData,
          ...image,
        })

        return objectData
      } else if (projectData.projectRequirments.dataType === 'video') {
        const allObjectData = []
        dispatch(asyncActionStart())
        for (const img of image) {
          const objectsRef = collection(
            store,
            'Projects',
            projectData.projectID,
            'dataset',
            isLidar ? image.pcdID : image.imageID,
            'objects'
          )
          const objectsSnapshot = await getDocs(objectsRef)
          const annot: any = {}
          const customerAnnot: any = {}

          objectsSnapshot.forEach((doc) => {
            const { objectName, groundTruth, customerAnnotation } = doc.data()

            if (groundTruth && groundTruth.length) {
              annot[objectName] = groundTruth
              if (customerAnnotation?.length) {
                customerAnnot[objectName] = customerAnnotation
              }
            }
          })
          const objectData = {
            annotation: annot,
            customerAnnotation: customerAnnot,
          }
          allObjectData.push({
            ...objectData,
            ...img,
          })
        }
        setRightBarData(allObjectData)
        return allObjectData
      } else if (projectData.projectRequirments.dataType === 'lidar') {
        dispatch(asyncActionStart())
        const objectsRef = collection(
          store,
          'Projects',
          projectData.projectID,
          'dataset',
          isLidar ? image.pcdID : image.imageID,
          'objects'
        )
        const objectsSnapshot = await getDocs(objectsRef)
        const annot: any = {}
        const customerAnnot: any = {}

        const promises: any = objectsSnapshot.docs.map(async (doc) => {
          const { objectName, groundTruth, customerAnnotation } = doc.data()
          // get annotation collection and order by crated Date and limit 1
          if (isAnnotation) {
            const annotRef = collection(
              store,
              'Projects',
              projectData.projectID,
              'dataset',
              isLidar ? image.pcdID : image.imageID,
              'objects',
              doc.id,
              'annotations'
            )
            const annotQuery = query(
              annotRef,
              orderBy('timestamp', 'desc'),
              limit(1)
            )
            const annotSnapshot = await getDocs(annotQuery)
            const annotData = annotSnapshot.docs.map((doc) => doc.data())
            if (annotData.length) annot[objectName] = annotData[0]?.annotation
          }

          if (groundTruth && groundTruth.length) {
            annot[objectName] = groundTruth
            if (customerAnnotation?.length) {
              customerAnnot[objectName] = customerAnnotation
            }
          }
        })
        await Promise.all(promises)
        const objectData = {
          annotation: annot,
          customerAnnotation: customerAnnot,
        }
        setRightBarData({
          ...objectData,
          ...image,
        })

        return objectData
      }
    } catch (error) {
    } finally {
      dispatch(asyncActionFinish())
    }
  }

  async function veriphyPhoneNumber(
    phoneNumber: string,
    recaptchaVerifier: ApplicationVerifier
  ) {
    const User: any = auth.currentUser
    const session = await multiFactor(User).getSession()

    const phoneInfoOptions = {
      session,
      phoneNumber,
    }

    const phoneAuthProvider = new PhoneAuthProvider(auth)
    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      )
      return verificationId
    } catch (error) {
      const e: any = error
      console.log(`Error ${e.code}: ${e.message}`)
      if (e.code === 'auth/requires-recent-login') {
        return e.code
      }
    }
  }

  async function enrollUser(
    verificationCodeID: string,
    verificationCode: string
  ) {
    const phoneAuthCredential = PhoneAuthProvider.credential(
      verificationCodeID,
      verificationCode
    )
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(phoneAuthCredential)

    try {
      const User: any = auth.currentUser
      await multiFactor(User).enroll(
        multiFactorAssertion,
        'Personal phone number'
      )
      // after enrollment, in firebase customer collection, set tfaDate to now
      await setDoc(
        doc(store, 'Customers', user.uid),
        {
          tfaDate: Date.now(),
          tfa: true,
        },
        { merge: true }
      )

      return true
    } catch (error) {
      const e: any = error
      notify('FAIL', `Error ${e.code}: ${e.message}`)
      return false
    }
  }

  async function verifyUserMFA(
    error: MultiFactorError,
    recaptchaVerfier: ApplicationVerifier,
    selectedIndex: number
  ) {
    const resolver = getMultiFactorResolver(auth2, error)

    if (
      resolver.hints[selectedIndex].factorId ===
      PhoneMultiFactorGenerator.FACTOR_ID
    ) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[selectedIndex],
        session: resolver.session,
      }

      const phoneAuthProvider = new PhoneAuthProvider(auth2)

      try {
        const verificationID = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerfier
        )

        return { verificationID, resolver }
      } catch (error) {
        const e: any = error
        console.log(`Error ${e.code}: ${e.message}`)
        return false
      }
    }
  }

  async function verifyUserEnrolled(
    verificationMFA: { verificationID: string; resolver: MultiFactorResolver },
    verificationCode: string
  ) {
    const { verificationID, resolver } = verificationMFA
    const credentials = PhoneAuthProvider.credential(
      verificationID,
      verificationCode
    )
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials)

    try {
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
      // after enrollment, in firebase customer collection, set tfaDate to now

      return userCredential
    } catch (error) {
      return false
    }
  }

  async function updateTFADate(user: any) {
    await setDoc(
      doc(store, 'Customers', user.uid),
      {
        tfaDate: Date.now(),
      },
      { merge: true }
    )
  }

  async function guidelineTextUpload(projectData: any, text: any, label: any) {
    const guidelinesRef = collection(
      store,
      'Projects',
      projectData.projectID,
      'guidelines'
    )
    console.log(text, label)
    if (projectData.projectRequirments.annotationType === 'NER') {
      for (let i = 0; i < text.length; i++) {
        await addDoc(guidelinesRef, {
          text: text[i],
          createdAt: Date.now(),
        })
      }
    } else {
      for (let i = 0; i < text.length; i++) {
        await addDoc(guidelinesRef, {
          text: text[i],
          label: label[i],
          createdAt: Date.now(),
        })
      }
    }
  }

  async function guidelineLLMUpload(projectData: any, llmData: any) {
    const guidelinesRef = collection(
      store,
      'Projects',
      projectData.projectID,
      'guidelines'
    )
    const guideline = await getDocs(guidelinesRef)
    // llmdata is File type
    if (llmData.src && llmData.src.name) {
      const storageRef = ref(
        storage,
        `Projects/${projectData.projectID}/mlm/${llmData.src.name}`
      )
      const snapshot = await uploadBytes(storageRef, llmData.src)
      llmData.src = await getDownloadURL(snapshot.ref)
    }
    if (guideline.docs.length > 0) {
      const guidelineRef = doc(
        store,
        'Projects',
        projectData.projectID,
        'guidelines',
        guideline.docs[0].id
      )
      await updateDoc(guidelineRef, {
        ...llmData,
        createdAt: Date.now(),
      })
    } else {
      await addDoc(guidelinesRef, {
        ...llmData,
        createdAt: Date.now(),
      })
    }
  }

  async function updateGuidelineText(projectData: any, guideline: any) {
    const guidelineRef = doc(
      store,
      'Projects',
      projectData.projectID,
      'guidelines',
      guideline.pk
    )
    const label = Object.values<any>(guideline.areas)[0].results[0].value
      .choices[0]

    await updateDoc(guidelineRef, {
      label: [label],
    })
  }

  async function updateGuidelineNER(
    projectData: any,
    areas: any,
    relations: any,
    guidelineID: any
  ) {
    const guidelineRef = doc(
      store,
      'Projects',
      projectData.projectID,
      'guidelines',
      guidelineID
    )

    await updateDoc(guidelineRef, {
      areas,
      relations,
    })
  }

  async function customSignIn(token: any) {
    return signInWithCustomToken(auth, token)
  }

  async function listTickets() {
    if (!user) return
    const ticketsRef = collection(store, 'Customers', user.uid, 'tickets')
    const ticketsSnapshot = await getDocs(ticketsRef)
    const tickets = ticketsSnapshot.docs.map((doc) => {
      const ticket = doc.data()
      return {
        ...ticket,
        id: doc.id,
      }
    })
    return tickets
  }

  async function getTicket(ticketID: string) {
    if (!user) return
    const ticketRef = doc(store, 'Customers', user.uid, 'tickets', ticketID)
    const ticketSnapshot = await getDoc(ticketRef)
    const ticket = ticketSnapshot.data()
    return ticket
  }

  async function createTicket(ticket: any) {
    if (!user) return
    const ticketRef = collection(store, 'Customers', user.uid, 'tickets')
    const response = await addDoc(ticketRef, ticket)
    const ticketSnapshot = await getDoc(doc(ticketRef, response.id))
    const ticketData = ticketSnapshot.data()
    return ticketData
  }

  async function sendMessageToTicket(ticketID: string, message: any) {
    if (!user) return
    const ticketRef = doc(store, 'Customers', user.uid, 'tickets', ticketID)

    await updateDoc(ticketRef, {
      chat: arrayUnion(message),
      [`status.isActive`]: true,
    })
  }

  async function createDirectory(directoryRef: any) {
    try {
      await getMetadata(directoryRef)
    } catch (error) {
      await uploadString(directoryRef, '')
    }
  }
  // async function addPdfFileToStorage(pdfFiles: any[], projectID: string) {
  //   const uploadPromises = pdfFiles.map(async (pdf) => {
  //     const fileName = pdf.pdfName
  //     const fileData = pdf.pdfFile // assuming pdfData is the actual file data
  //     const storageRef = ref(
  //       storage,
  //       `Projects/${projectID}/guidelinePDF/${fileName}`
  //     )
  //     await createDirectory(storageRef)

  //     try {
  //       return new Promise(async (resolve, reject) => {
  //         const metadata = {
  //           contentType: 'application/pdf',
  //         }
  //         const uploadTask = uploadBytesResumable(
  //           storageRef,
  //           fileData,
  //           metadata
  //         )

  //         uploadTask.on('state_changed', async (snapshot) => {
  //           const progress = Math.round(
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           )
  //           if (progress === 100) {
  //             setTimeout(() => {
  //               resolve(`Projects/${projectID}/guidelinePDF/${fileName}`)
  //             }, 1000)
  //           }
  //         })
  //       })
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   })
  //   const storagePath = await Promise.all(uploadPromises)

  //   return storagePath
  // }

  async function addPdfFileToStorage(
    pdfFiles: any[],
    projectID: string,
    setPdfFiles: any
  ) {
    const uploadPromises = pdfFiles.map(async (pdf) => {
      const fileName = pdf.pdfName
      const fileData = pdf.pdfFile // assuming pdfData is the actual file data
      const storageRef = ref(
        storage,
        `Projects/${projectID}/guidelinePDF/${fileName}`
      )
      await createDirectory(storageRef)

      try {
        return new Promise(async (resolve, reject) => {
          const metadata = {
            contentType: 'application/pdf',
          }
          const uploadTask = uploadBytesResumable(
            storageRef,
            fileData,
            metadata
          )

          uploadTask.on('state_changed', async (snapshot) => {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            console.log(progress, fileName)
            setPdfFiles((prev: any[]) => [
              ...prev.map((file: any) => {
                if (file.pdfName === fileName) {
                  return {
                    ...file,
                    percentage: progress,
                  }
                }
                return file
              }),
            ])
            if (progress === 100) {
              setTimeout(() => {
                resolve(`Projects/${projectID}/guidelinePDF/${fileName}`)
              }, 1000)
            }
          })
        })
      } catch (error) {
        console.log(error)
      }
    })
    const storagePath = await Promise.all(uploadPromises)

    return storagePath
  }

  async function handleDeletePdfFileFromStorage(
    pdfPath: string,
    projectID: string,
    pdfName: string
  ) {
    try {
      console.log(pdfPath)
      const storageRef = ref(storage, pdfPath)
      await deleteObject(storageRef)

      const projectRef = doc(store, 'Projects', projectID)
      const projectSnapshot = await getDoc(projectRef)
      const projectData: any = projectSnapshot.data()
      const guidelinePdfs = projectData.guidelinePdfs
      const newGuidelinePdfs = guidelinePdfs.filter(
        (pdf: any) => pdf.pdfName !== pdfName
      )

      await updateDoc(projectRef, {
        guidelinePdfs: newGuidelinePdfs,
      })

      return true
    } catch (error) {
      console.log(error)
      return false
    }
  }

  async function sendAttachmentsToTicket(attachments: any) {
    if (!user) return
    const urls: any = []
    return new Promise((resolve, reject) => {
      attachments.forEach((attachment: any) => {
        const storageRef = ref(
          storage,
          `Customers/${user.uid}/tickets/` + attachment.name
        )
        const uploadTask = uploadBytesResumable(storageRef, attachment)

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (error) => {},
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
            urls.push(downloadURL)

            if (urls.length === attachments.length) {
              resolve(urls)
            }
          }
        )
      })
    })
  }

  async function setGuidelineTicket(
    projectID: string,
    ticketID: string,
    payload: any
  ) {
    const ticketRef = doc(
      store,
      'Projects',
      projectID,
      'guidelineTickets',
      ticketID
    )
    await setDoc(ticketRef, payload, { merge: true })
  }

  const firebase: any = {
    app,
    store,
    auth,
    storage,
    functions,
    api: {
      getDocByPath,
      enrollUser,
      handleDeletePdfFileFromStorage,
      addPdfFileToStorage,
      verifyUserEnrolled,
      veriphyPhoneNumber,
      verifyUserMFA,
      getAllDocsByPath,
      getAllDocsByPathAndQuery,
      addZipFileToStorage,
      addYoloZipFileToStorage,
      deleteFileStorage,
      deleteDoc,
      getFileUrlByPath,
      getGuidelineRegions,
      getGuidelineImages,
      getGuidelineTextData,
      guidelineTextUpload,
      guidelineLLMUpload,
      updateGuidelineText,
      updateGuidelineNER,
      getAttributeAnnotation,
      addAttributeAnnotation,
      listenToProjectDataset,
      getImagesInRange,
      listenToAnnotations,
      listenToTrackingUsers,
      listenToSubscriptions,
      listenToNotifications,
      listenToProjects,
      getAddedCustomers,
      getAllTaskUser,
      getUsagesWithProjectIDs,
      getAnnotations,
      getTrackingUsers,
      getNotifications,
      signin,
      signout,
      createNewAccount,
      reauthenticate,
      updateUserPassword,
      sendEmailResetPassword,
      addCustomer,
      removeCustomer,
      setAutoAddDomain,
      getBlobUrlByPath,
      getAllProjects,
      listenToProjectByName,
      getProject,
      uploadDataManagementImages,
      getAcceptedFiles,
      uploadImagesByPath,
      uploadGuidelineImages,
      uploadFileProfile,
      getFilesDataFromDataset,
      updateDocument,
      deleteDocument,
      pointToCollection,
      updateCustomerFirstOnboarding,
      updateCustomerIsUnread,
      updateCustomerSubscriptionStatus,
      updateCustomerSubscriptionType,
      updateCustomerSubscriptionPeriod,
      updateProfile,
      upadateDashboardSettings,
      upadateDashboardSettingsEmail,
      updateProfilePic,
      updateCompanyProfile,
      updateCompanyLogo,
      updateProfileInAllProjects,
      updateCustomerWelcomeMessage,
      checkoutSessionRef,
      getExportDatas,
      getExport,
      getReviewTextDatas,
      getReviewTextDataEvaluation,
      updateTextDatas,
      getTasks,
      updateTask,
      deleteTask,
      getReviewImages,
      getReviewMoreImages,
      getReviewFilteredImages,
      getReviewMoreFilteredImages,
      getReviewImagesData,
      getReviewRightBarData,
      getUser,
      customSignIn,
      updateTFADate,
      updateMLMGuidelineImage,
      listTickets,
      getTicket,
      createTicket,
      sendMessageToTicket,
      sendAttachmentsToTicket,
      updateFineTunedPrompt,
      addJSONFileToStorage,
      updateProjectDescription,
      setGuidelineTicket,
      getAnnotators,
      removeUserFromWatUsers,
      setServebleTrue,
      deleteAdminPDF,
      deleteTickets,
      getSubscriptionInvoices,
    },
  }

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}

export default FirebaseProvider
